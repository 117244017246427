import * as graphConfig from 'src/app/config/graph-config.json';
import {Label} from "ng2-charts";

export class GraphHelper {
    times: object = {};

    //HOURS = 24;
    HOURS = 12;

    sensorComments: any[] = [];

    filteringK: string = null;

    filteringSensor: string = null;

    langValue: string  = '';

    // sensor -> number[] = [];
    numbers: object = {};
    // sensor -> key -> number[]
    dataItems: object = {};
    // sensor -> Date[] = [];

    accessibleSensors: string[] = [];
    accessibleSensorLastMeasurement: any[] = [];
    locationName = '';
    locationId = '';

    values: number[] = [];
    rawValues: number[] = [];

    minValue = 0;
    meanValue = 0;
    maxValue = 0;

    labels: Label[] = [];

    public extraOptions: any = {
        responsive: true,
        scales: {
            xAxes: [
                {
                    offset: true,
                    type: 'time',
                    time: {
                        //unit: false,
                        displayFormats: {
                            hour: 'HH:mm',
                            day: 'DD.MM',
                            month: 'MM.YYYY'
                        },
                        tooltipFormat: 'YYYY-MM-DD HH:mm:ss',
                    },
                    ticks: {
                        autoSkip: false,
                        maxTicksLimit: 20,
                        minRotation: 45,
                    }
                },
            ],
        },
        elements: {
            line: {
                tension: 0, // disables bezier curves
            },
        },

    };

    sensorIgnores = {
        x: {
            name: undefined,
        },
        y: {
            name: undefined,
        },
        z: {
            name: undefined,
        },
        function: {
            name: undefined,
        },
        averageMode: {
            name: undefined,
        },
        downlinkCommandAck: {
            name: undefined,
        },
        duplicateData: {
            name: undefined,
        },
        transmitIntervalMinutes: {
            name: undefined,
        },
        voltage: {
            name: undefined
        },
        alarmClearReason: {
            name: undefined
        },
        receivedTimestamp: {
            name: undefined
        },
        Time: {
            name: undefined
        },
        DevEUI: {
            name: undefined
        },
        from: {
            name: undefined
        },
        receivedAt: {
            name: undefined
        },
        text: {
            name: undefined
        },
        messageId: {
            name: undefined
        },
        cleanText: {
            name: undefined
        },
        to: {
            name: undefined
        },
        smsCount: {
            name: undefined
        },
        pairedMessageId: {
            name: undefined
        },
        price: {
            name: undefined
        },
        callbackData: {
            name: undefined
        },
        messageCount: {
            name: undefined
        },
        pendingMessageCount: {
            name: undefined
        },
        state: {
            name: undefined
        },
        fPort: {
            name: undefined
        },
        deviceAuxiliaryOperation: {
            name: undefined
        },
        fw_version: {
            name: undefined
        },
        hw_version: {
            name: undefined
        },
        shutdownType: {
            name: undefined
        },
        deviceMode: {
            name: undefined
        },
        batteryPercentage: {
            name: undefined
        }

    };

    getTicks = (k, language) => {
        if (graphConfig.MEASUREMENTS[k]) {
            const spk = graphConfig.MEASUREMENTS[k];
            if (spk.categories && spk.categories[language]) {
                const cats = spk.categories[language];
                return {
                    suggestedMin: spk.min,
                    suggestedMax: spk.max,
                    // Include a dollar sign in the ticks
                    callback: (value, index, values) => {
                        if (cats[value]) {
                            return cats[value];
                        } else {
                            return '';
                        }
                    },
                };
            } else {
                return {
                    suggestedMin: spk.min,
                    suggestedMax: spk.max,
                };
            }
        } else {
            return {};
        }
    };


    getDataSpec = (k, values, lbl) => {
        if (graphConfig.MEASUREMENTS[k]) {
            return {
                label: lbl,
                backgroundColor: this.isAddozDevice(k) ? "rgba(255,255,255,0)" : graphConfig.MEASUREMENTS[k].bgColor,
                borderColor: this.isAddozDevice(k) ? this.getBarBorderColor(values) : graphConfig.MEASUREMENTS[k].color,
                borderWidth: 1,
                data: values,
                pointRadius: this.isAddozDevice(k) ? 7 : 1,
                pointHoverBackgroundColor: this.isAddozDevice(k) ? this.getBarColor(values) : graphConfig.MEASUREMENTS[k].color,
                pointBorderColor: this.isAddozDevice(k) ? this.getBarBorderColor(values) : graphConfig.MEASUREMENTS[k].color,
                pointBackgroundColor: this.isAddozDevice(k) ? this.getBarColor(values) : graphConfig.MEASUREMENTS[k].color,
                pointHoverRadius: this.isAddozDevice(k) ? 10 : 3,
                barThickness: 50,
                categoryPercentage: 1.0,
                barPercentage: 1
            };
        } else {
            return {
                label: k,
                backgroundColor: '#FFAA99',
                borderColor: '#AA5522',
                data: values,
                pointRadius: 1,
                pointHoverRadius: 5,
                barThickness: 20,
                categoryPercentage: 1.0,
                barPercentage: 0.5
            };
        }
    };

    byTimeReceived(a: any, b: any): number {
        const at = a.timeReceivedUTC;
        const bt = b.timeReceivedUTC;
        if (at && bt) {
            if (at < bt) {
                return -1;
            }
            if (at > bt) {
                return 1;
            }
            return 0;
        }
        return -1;
    }

    public byTimestamp(a: any, b: any): number {
        const at = a.timestampUTC;
        const bt = b.timestampUTC;
        if (at && bt) {
            if (at < bt) {
                return -1;
            }
            if (at > bt) {
                return 1;
            }
            return 0;
        }
        return -1;
    }

    public getSampleInterval(sensor: string) {
        const ts = this.times[sensor];
        if (ts && ts.length > 1) {
            let msecsum = 0;
            for (let i = 1; i < ts.length; i++) {
                msecsum += ts[i].getTime() - ts[i - 1].getTime();
            }
            return msecsum / ts.length;
        }
        return -1;
    }

    public getLastSampleInterval(sensor: string) {
        const ts = this.times[sensor];
        if (ts && ts.length > 1) {
            const msecsum = ts[ts.length - 1].getTime() - ts[ts.length - 2].getTime();
            return msecsum;
        }
        return -1;
    }

    public lastIntervalIcon(sensor: string) {
        const score = this.lastIntervalScore(sensor);
        if (score === -1) {
            return 'sentiment_satisfied_alt';
        }
        if (score === 0) {
            return 'check_circle_outline';
        }
        return 'error_outline';
    }

    public lastIntervalScore(sensor: string) {
        const si = this.getSampleInterval(sensor);
        const lastInterval = this.getLastSampleInterval(sensor);
        if (lastInterval < si && lastInterval !== -1) {
            return -1;
        }
        if (lastInterval < si + 60000 && lastInterval !== -1) {
            return 0;
        }
        return 1;
    }

    public getMeasurementAgeDays(sensor: string) {
        return Math.round(this.getMeasurementAgeMinutes(sensor) / 60 / 24);
    }

    public getMeasurementAgeHours(sensor: string) {
        return Math.round(this.getMeasurementAgeMinutes(sensor) / 60);
    }

    public getMeasurementAgeMinutes(sensor: string) {
        const ts = this.times[sensor];
        const dNow = new Date();
        if (ts && ts.length > 0) {
            const msec = dNow.getTime() - ts[ts.length - 1].getTime();
            return this.getAgeMinutes(msec);
        }
        return this.getAgeMinutes(this.HOURS * 60 * 60 * 1000);
    }

    public getAgeMinutes(msec: number) {
        const mins = Math.round(msec / 1000 / 60);
        if (mins < 1) {
            return 0;
        }
        if (mins < 60) {
            return mins;
        }
        const hours = Math.round(mins / 60);
        if (hours < 25) {
            return mins;
        }
        return mins;
    }

    public getMeasurementAge(sensor: string) {
        const ts = this.times[sensor];
        const dNow = new Date();
        if (ts && ts.length > 0) {
            const msec = dNow.getTime() - ts[ts.length - 1].getTime();
            return this.getAge(msec);
        }
        return 'more than ' + this.getAge(this.HOURS * 60 * 60 * 1000);
    }

    public getAge(msec: number) {
        const mins = Math.round(msec / 1000 / 60);
        if (mins < 1) {
            return 'less than a minute ago';
        }
        if (mins < 60) {
            return mins + ' minutes ago';
        }
        const hours = Math.round(mins / 60);
        if (hours < 25) {
            return hours + ' hours ago';
        }
        const days = Math.round(hours / 24);
        return days + ' days ago';
    }

    public showError(jqXHR: any, textStatus: string, errorThrown: string): void {
        console.log('Error: ' + textStatus + ': ' + errorThrown + `: ${JSON.stringify(jqXHR, null, 2)}`);
    }

    public tempScale(tempC: number, minValue: number): number {
        return Math.max(tempC - minValue, 0);
    }

    public addDataItemKeys(res: any, sensor: string, index: number) {
        for (let key in res) {
            const keyVal = key;
            key = key.toUpperCase() === 'MOISTURE' ? 'humidity' : (key.toUpperCase() === 'PERSONWEIGHT') ? 'weightAvg' : key;


            if (key === 'vdd' || key.startsWith('time') || this.isVesCupHiddenKey(key) || this.isVayyarHiddenKey(key) || this.isSosButtonHiddenKey(key)) {
                continue;
            }
            if (!this.dataItems[sensor]) {
                this.dataItems[sensor] = {};
            }

            if (!this.dataItems[sensor][key]) {
                // console.log("Adding key: " + key)
                this.dataItems[sensor][key] = new Array<number>(this.numbers[sensor].length);
            }
             //console.log("Adding value: " + res[key])
            this.dataItems[sensor][key][index] = res[keyVal];
        }
    }

    filterKeys(sensorData: any, keys: string[], sensor: string, alarmData: any) {
        const filteredKeys = [];
        const sortedKeys = [];
        for (const k of keys) {
            if (sensorData.hasOwnProperty(k)) {
                // Skip some sensors like x, y, z
                if (this.sensorIgnores[k] && this.sensorIgnores[k].name == undefined) {

                } else {
                    if (alarmData) {
                        const alarmRes = alarmData.alarmList.filter(alarm => {
                            const alarmMeasurementType = alarm.measurementType.toUpperCase() === 'MOISTURE' ? 'HUMIDITY' : (alarm.measurementType.toUpperCase() === 'PERSONWEIGHT') ? 'WEIGHTAVG' : alarm.measurementType.toUpperCase();
                            return alarmMeasurementType == k.toUpperCase() && sensor === alarm.devId;
                        });
                        if (alarmRes.length > 0) {
                            sortedKeys.push({key: k, alarm: true});
                        } else {
                            sortedKeys.push({key: k, alarm: false});
                        }
                    } else {
                        sortedKeys.push({key: k, alarm: false});
                        filteredKeys.push(k);
                    }
                }

            }
        }
        sortedKeys.sort(this.sortByAlarms);
        return sortedKeys;
    }

    sortByAlarms(a: any, b: any): number {
        if (a.alarm && b.alarm) {
            return 0;
        } else if (a.alarm && !b.alarm) {
            return -1;
        } else if (!a.alarm && b.alarm) {
            return 1;
        } else {
            return 0;
        }
    }

    public checkGraphHours() {
        if (this.isHenkausDevice(null, this.filteringSensor, false)) {
            this.extraOptions.scales.xAxes[0].time.unit = 'hour';
        } else {
            if (this.HOURS <= 48) {
                this.extraOptions.scales.xAxes[0].time.unit = 'hour';
            } else if (this.HOURS > 720) {
                this.extraOptions.scales.xAxes[0].time.unit = 'month';
            } else if (this.HOURS > 24) {
                this.extraOptions.scales.xAxes[0].time.unit = 'day';
            } else {
                this.extraOptions.scales.xAxes[0].time.unit = 'hour';
            }
        }


        //this.extraOptions.scales.xAxes[0].time.unit = 'day';
        //this.extraOptions.scales.xAxes[0].time.stepSize = 1;
        //this.extraOptions.scales.xAxes[0].ticks = {min: 1614588385, max: 1614786385};
        this.numbers = {};
        this.times = {};
    }

    public getGraphOptions(karr, sensor, tempKey, maintainAspectRatio = true, language) {
        this.rawValues = [];
        this.values = [];
        this.labels = [];
        this.meanValue = 0;
        this.maxValue = 0;
        this.minValue = 0;
        for (let i = 0; i < karr.length; i++) {
            this.labels.push(this.times[sensor][i]);
            if (karr.hasOwnProperty(i)) {
                this.rawValues.push(karr[i]);
            } else {
                this.rawValues.push(-777);
            }
        }
        if (this.isAddozDevice(tempKey)) {
            this.values = this.getAddozValues(karr);
        } else if (this.isVayyarDevice(tempKey, karr)) {
            if (tempKey == 'status') {
                this.values = this.getVayyarStatusValues(karr);
                this.values = this.interpVayyarValues(this.values);
            } else if (tempKey == 'trackerTargets') {
                this.values = this.getVayyarTrackerValues(karr);
            }
        } else {
            this.values = this.interp(this.rawValues);
            this.calculateMinMaxMeanValues();
        }

        const options = this.extraOptions;
        options.scales.yAxes = [
            {
                ticks: this.getTicks(tempKey, language),
            },
        ];

        options.responsive = true;

        options.maintainAspectRatio = maintainAspectRatio;
        if (tempKey == 'distance') {
            options.spanGaps = true;
        }

        if (this.isHenkausDevice(tempKey, null, false)) {
            options.spanGaps = true;
            options.annotation = {
                annotations: [
                    {
                        type: "line",
                        mode: "horizontal",
                        scaleID: "y-axis-0",
                        value: 12,
                        borderColor: "#ff5200",
                        borderWidth: 2,
                        borderDash: [10, 6],
                    },
                    {
                        type: "line",
                        mode: "horizontal",
                        scaleID: "y-axis-0",
                        value: 18,
                        borderColor: "#ff5200",
                        borderWidth: 2,
                        borderDash: [10, 6],
                    }
                ]
            };
        } else {
            options.annotation = [];
        }


        if (this.isAddozDevice(tempKey) || this.isVayyarDevice(tempKey, karr) || this.isSosButtonDevice(tempKey)) {
            options.tooltips = {
                callbacks: {
                    label: function (item, data) {
                        const spk = graphConfig.MEASUREMENTS[tempKey];
                        if (spk.categories && spk.categories[language]) {
                            const cats = spk.categories[language];
                            return cats[item.value];
                        }
                    }
                }
            }
        } else {
            if (this.isHoivitaScale(tempKey)) {
                options.tooltips = {
                    callbacks: {
                        label: function(item, data) {
                            var datasetLabel = data.datasets[item.datasetIndex].label || '';
                            if (tempKey === 'durationOnScale') {
                                var duration = parseFloat(item.value).toFixed(1);
                                var durationSplit = duration.split('.');
                                if (durationSplit.length > 1) {
                                    return durationSplit[0] + ' min ' + parseFloat('0.'+durationSplit[1]) * 60 + ' s';
                                } else {
                                    return durationSplit[0] + ' min';
                                }
                            }
                            return datasetLabel + ': ' + item.value + graphConfig.MEASUREMENT_LABEL[tempKey];
                        }
                    }
                }
            } else {
                options.tooltips = {
                    callbacks: {
                        label: function(item, data) {
                            var datasetLabel = data.datasets[item.datasetIndex].label || '';
                            return datasetLabel + ': ' + item.value;
                        }
                    }
                }
            }

        }

        return options;
    }

    interpVayyarValues(values) {
        for (var i = 0; i < values.length; i++) {
            if (values[i] == undefined) {
                values[i] = 0;
            }
        }
        return values;
    }

    public isVayyarDevice(name: string, values: string[], sensor: string = null) {
        if (name === 'status') {
            if (sensor && this.dataItems[sensor]) {
                return this.dataItems[sensor].status.indexOf('monitoring') > -1 || this.dataItems[sensor].status.indexOf('fall_confirmed') > -1;
            } else if (values) {
                return values.indexOf('monitoring') > -1 || values.indexOf('fall_confirmed') > -1;
            }
        } else {
            return name == 'trackerTargets';
        }
        return false;
    }

    public isAddozDevice(name: string) {
        switch (name) {
            case 'dosemissed' :
            case 'technicalerror' :
            case 'batterywarning' :
            case 'addozStatus':
                return true;
            default:
                return false;

        }
    }

    public isHoivitaScale(name: string) {
        switch (name) {
            case 'batteryPercentage':
            case 'durationOnScale':
            case 'weightAvg':
            case 'weightMax':
                return true;
            default:
                return false;

        }
    }

    public isSosButtonDevice(name: string) {
        switch (name) {
            case 'event':
                return true;
            default:
                return false;

        }
    }


    private calculateMinMaxMeanValues() {
        let count = 0;
        let totalNr = 0;
        for (let val of this.values) {
            if (val) {
                if (this.minValue === 0 || this.minValue > val) {
                    this.minValue = val;
                }
                if (this.maxValue === 0 || this.maxValue < val) {
                    this.maxValue = val;
                }
                totalNr += val;
                count++;
            }
        }

        if (count == 0) {
            this.meanValue = 0;
        } else {
            const mean = totalNr / count;
            this.meanValue = Math.floor(mean * 100) / 100;
        }
    }

    public interp(data: number[]): number[] {
        const interpolatedData = data;
        let last = -777;
        let lastIndex = 0;
        let missing = false;
        for (let i = 0; i < data.length; i++) {
            const item = interpolatedData[i];
            if (item !== -777) {
                if (missing) {
                    for (let j = lastIndex; j < i; j++) {
                        interpolatedData[j] = last;
                    }
                }
                if (last === -777) {
                    missing = true;
                    last = item;
                } else {
                    lastIndex = i;
                    last = item;
                    missing = false;
                }
            } else {
                missing = true;
            }
        }

        if (missing) {
            // At least last data item was missing. Fill with last known value
            for (let j = lastIndex; j < data.length; j++) {
                interpolatedData[j] = last;
            }
        }

        return interpolatedData;
    }

    isVesCupHiddenKey(key: string) {
        const keys = [
            'connectivity',
            'designNumber',
            'packetNumber',
            'modelNumber',
            'region',
            'usbPogoConnected',
            'firmwareNumber',
            'packetType',
            'waterLeak',
            'liquidLevel',
        ];

        return keys.indexOf(key) !== -1;
    }

    isSosButtonHiddenKey(key: string) {
        const keys = [
            'zAxisAcceleration',
            'yAxisAcceleration',
            'xAxisAcceleration',
            'gpsLongitude',
            'gpsLatitude',
            'firstBeaconRssi',
            'firstBeaconAddress',
            'secondBeaconRssi',
            'secondBeaconAddress',
            'thirdBeaconRssi',
            'thirdBeaconAddress',
            'fourthBeaconRssi',
            'fourthBeaconAddress',
            'angular',
        ];
        return keys.indexOf(key) !== -1;
    }

    isVayyarHiddenKey(key: string) {
        const keys = [
            'endTimestamp',
            'extra',
            'isLearning',
            'isSilent',
            'isSimulated',
            'memoryUsage',
            'presenceDetected',
            'presenceRegionMap',
            'presenceTargetType',
            'roomPresenceIndication',
            'statusUpdateTimestamp',
            'type',
            'upTime',
            'vayyar',
            'wifiState',
            'exitReason',
            'factoryBurnTime',
            'hardware',
            'hwRevRadar',
            'model',
            'packageName',
            'serialProduct',
            'serialRadar',
            'versionCode',
            'versionName',
            'wifiMac'
        ];

        return keys.indexOf(key) !== -1;
    }

    private getBarColor(values: any) {
        var barColor = [];
        for (let val of values) {
            if (val == 1) {
                barColor.push("rgba(196,220,149,1)");
            } else if (val == 2) {
                barColor.push("rgba(255,43,121,1)");
            } else if (val == 3) {
                barColor.push("rgba(221,242,101,1)");
            } else {
                barColor.push("rgba(0,0,0,1)");
            }
        }
        return barColor;
    }

    private getBarBorderColor(values: any) {
        var barColor = [];
        for (let val of values) {
            if (val == 1) {
                barColor.push("rgba(42,56,64,1)");
            } else if (val == 2) {
                barColor.push("rgba(42,56,64,1)");
            } else if (val == 3) {
                barColor.push("rgba(42,56,64,1)");
            } else {
                barColor.push("rgba(255,255,255,1)");
            }
        }
        return barColor;
    }

    private getVayyarTrackerValues(rawValues) {
        var values = [];
        for (let i = 0; i < rawValues.length; i++) {
            values.push(this.getVayyarTrackerKey(rawValues[i]));
        }
        return values;
    }

    private getVayyarStatusValues(rawValues) {
        var values = [];
        for (let i = 0; i < rawValues.length; i++) {
            values.push(this.getVayyarDeviceKey(rawValues[i]));
        }
        return values;
    }

    private getAddozValues(rawValues) {
        var values = [];
        for (let i = 0; i < rawValues.length; i++) {
            values.push(this.getAddozDeviceKey(rawValues[i]));
        }
        return values;
    }

    getVayyarTrackerKey(value) {
        if (!value) {
            return 0;
        } else {
            return value.length >= 2 ? 2 : value.length;
        }
    }

    getVayyarDeviceKey(value: string) {
        switch (value) {
            case 'fall_confirmed':
                return 1;
            case 'monitoring' :
                return 0;
        }
    }

    getAddozDeviceKey(value: string) {
        switch (value) {
            case 'dosetaken':
                return 1;
            case 'dosemissed' :
                return 2;
            case 'batterywarning':
                return 3;
            case 'technicalerror':
                return 4;
        }
    }

    processDataItems(data: any, minTime: number, sensor: string): boolean {
        let arr: any[] = data;
        const useTs = false; // arr[0].hasOwnProperty('timestampUTC');
        arr.sort(this.byTimeReceived);
        // }
        // Fix bug: When there is spotty data, x-axis was getting smudged
        // with tons of timestamps for the large gap between e.g.
        // 2019-12-31 and 2020-01-23.
        const firstTimestamp = arr.findIndex((value, index, obj) => {
            return value.timeReceivedUTC > minTime;
        });
        if (firstTimestamp !== -1) {
            arr = arr.slice(firstTimestamp);
        }
        if (arr.length > 0) {
            let props = 0;
            for (const k in arr[0]) {
                if (arr[0].hasOwnProperty(k)) {
                    props++;
                }
            }
            if (props <= 2) {
                arr = arr.slice(1);
            }
            if (!this.numbers[sensor]) {
                this.numbers[sensor] = new Array<number>(arr.length);
                this.times[sensor] = [];
            } else if (this.numbers[sensor].length === 0) {
                this.numbers[sensor] = new Array<number>(arr.length);
                this.times[sensor] = [];
            }
            for (let i = 0; i < arr.length; i++) {
                const timeStamp: number = useTs ? arr[i].timestampUTC : arr[i].timeReceivedUTC;
                this.times[sensor].push(new Date(timeStamp));
                const theI = i;
                const ai = arr[i];
                const dataItemProcessed = this.handleDataItem(sensor, ai, theI);
                if (dataItemProcessed) {
                    return true;
                }
            }
            return false;
        }
    }

    private handleDataItem(sensor: string, res: any, index: number): boolean {
        if (res.temperature) {
            const tempValue: number = res.temperature;
            this.numbers[sensor][index] = tempValue;
            /*if (index + 1 === this.numbers[sensor].length) {
                this.addDataItemKeys(res, sensor, index);
                return true;
               // setTimeout(() => this.showWholeDashboard(sensor), 100);
            }*/
        } else if (res.temp) {
            const tempValue: number = res.temp;
            this.numbers[sensor][index] = tempValue;
        }
        this.addDataItemKeys(res, sensor, index);
        if (index + 1 === this.numbers[sensor].length) {
            return true;
            //setTimeout(() => this.showWholeDashboard(sensor), 100);
        }
        return false;
    }

    public isHenkausDevice(tempKey: string, sensor: string, checkDistance: boolean) {
        if (tempKey != null) {
            if (checkDistance) {
                return tempKey.toLowerCase() === 'rpm' || tempKey.toLowerCase() === 'distance';
            }
            return tempKey.toLowerCase() === 'rpm';
        } else if (sensor != null) {
            return sensor.toUpperCase() === 'SKANE1' || sensor.toUpperCase() === 'LUTERGO' || sensor.toUpperCase() === 'SKOTI1' || sensor.toUpperCase() === 'SKOTI2' || sensor.toUpperCase() === 'PILOTAU' || sensor.toUpperCase() === 'HOIVITA';
        }
        return false;
    }
}
