import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import {CommonModule, registerLocaleData} from '@angular/common';
import localeFi from '@angular/common/locales/fi';
import localeFiExtra from '@angular/common/locales/extra/fi';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

registerLocaleData(localeFi, 'fi-FI', localeFiExtra);

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MapComponent } from './views/map/map.component';
import { HeaderComponent } from './header/header.component';
import { CallbackComponent } from './callback/callback.component';
import { AlarmsListComponent } from './sidebars/alarms-list/alarms-list.component';
import {AlarmService} from './services/alarm.service';
import {HttpService} from './core/lib/services/http.service';
import {CustomMaterialModule} from './core/module/CustomMaterialModule';
import {FullscreenOverlayContainer, OverlayContainer} from '@angular/cdk/overlay';
import {PopoverComponent} from './core/popover/popover.component';
import {AccountOverlayComponent} from './overlays/account/account-overlay.component';
import {CareFacilityListComponent} from './sidebars/care-facility-list/care-facility-list.component';
import {CareFacilityDetailOverlayComponent} from './overlays/care-facility-detail/care-facility-detail-overlay.component';
import {CardComponent} from './views/card/card.component';
import {FloorPlanDetailOverlayComponent} from './overlays/floor-plan-detail/floor-plan-detail-overlay.component';
import {LocationService} from './services/location.service';
import {InterceptService} from 'src/app/core/lib/interceptors/intercept.service';
import {AuthService} from 'src/app/core/auth/_services/auth.service';
import {LayoutComponent} from './views/layout.component';
import {AlarmDismissDialogComponent} from './overlays/alarm-dismiss-dialog/alarm-dismiss.dialog.component';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {UserService} from './services/user.service';
import {
  ActionNotificationComponent,
  AlertComponent,
  DeleteEntityDialogComponent,
  FetchEntityDialogComponent,
  UpdateStatusDialogComponent
} from './core/lib/crud';
import {LayoutUtilsService} from './core/lib/services/layout-utils.service';
import {LanguageSelectorComponent} from './header/language-selector/language-selector.component';
import {LoaderComponent} from './core/lib/loader/loader.component';
import {ViewSelectorComponent} from './header/view-selector/view-selector.component';
import {GetCustomerNameForBuilding} from './core/lib/pipe/get-customer-name-building.pipe';
import {AlarmListService} from './services/alarm-list.service';
import {NgxPermissionsModule} from 'ngx-permissions';
import {ErrorModule} from './views/error/error.module';
import {NotificationsOverlayComponent} from './overlays/notifications/notifications-overlay.component';
import {SingleGraphDialogComponent} from "./overlays/single-graph-dialog/single-graph.dialog.component";
import {TrackerHistoryOverlayComponent} from "./overlays/tracker-history/tracker-history-overlay.component";
import {TrackerService} from "./services/tracker.service";
import {ChartsModule, ThemeService} from "ng2-charts";
import {GraphComponent} from "./views/partials/graph/graph.component";

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, './assets/i18n/', '.json');
}

@NgModule({
    declarations: [
        AppComponent,
        LayoutComponent,
        MapComponent,
        CardComponent,
        HeaderComponent,
        CallbackComponent,
        AlarmsListComponent,
        CareFacilityListComponent,
        LoaderComponent,
        PopoverComponent,
        FloorPlanDetailOverlayComponent,
        CareFacilityDetailOverlayComponent,
        TrackerHistoryOverlayComponent,
        AccountOverlayComponent,
        NotificationsOverlayComponent,
        AlarmDismissDialogComponent,
        SingleGraphDialogComponent,
        UpdateStatusDialogComponent,
        FetchEntityDialogComponent,
        AlertComponent,
        DeleteEntityDialogComponent,
        ActionNotificationComponent,
        LanguageSelectorComponent,
        ViewSelectorComponent,
        GetCustomerNameForBuilding,
        GraphComponent
    ],
  imports: [
    NgxPermissionsModule.forRoot(),
    ErrorModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    BrowserAnimationsModule,
    CustomMaterialModule,
    CommonModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    ChartsModule
  ],
  providers: [
    InterceptService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptService,
      multi: true
    },
    AuthService,
    { provide: LOCALE_ID, useValue: 'fi-FI' },
    CookieService,
    AlarmService,
    HttpService,
    LocationService,
    TrackerService,
    LayoutUtilsService,
    UserService,
    AlarmListService,
    ThemeService,
    {
      provide: OverlayContainer,
      useClass: FullscreenOverlayContainer
    },
  ],
  // tslint:disable-next-line:max-line-length
  entryComponents: [
      PopoverComponent,
      FloorPlanDetailOverlayComponent,
      AlarmDismissDialogComponent,
      SingleGraphDialogComponent,
      AccountOverlayComponent,
      NotificationsOverlayComponent,
      CareFacilityDetailOverlayComponent,
      TrackerHistoryOverlayComponent,
      DeleteEntityDialogComponent,
      AlertComponent,
      FetchEntityDialogComponent,
      UpdateStatusDialogComponent,
      ActionNotificationComponent,
      GraphComponent
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
