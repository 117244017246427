import {AfterViewInit, Component, OnInit, HostListener, ViewEncapsulation, TemplateRef, OnDestroy} from '@angular/core';
import { LocalDataService } from 'src/app/services/local-data.service';
import {NavigationEnd, Router} from '@angular/router';
import {AlarmService} from '../../services/alarm.service';
import {AlarmDetailsModel} from '../../models/alarm-details.model';
import {Popover} from '../../core/popover/popover.service';
import {AuthService} from 'src/app/core/auth/_services/auth.service';
import {LocalDataConstants} from '../../core/lib/utility/local-data.constants';
import {Building, LocationAreaDetail, LocationAreaDetailResponse} from '../../AdminApi';
import {LocationAreaNameModel} from '../../models/location-area-name.model';
import {LocationService} from '../../services/location.service';
import {CareFacilityDetailOverlayComponent} from '../../overlays/care-facility-detail/care-facility-detail-overlay.component';
import {CommonService} from "../../services/common.service";
import {Subscription} from "rxjs";
import {distinctUntilChanged, filter} from "rxjs/operators";

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CardComponent implements OnInit, AfterViewInit, OnDestroy {
  timer: any;
  REFRESH_RATE = 30 * 1000; // 30 sec refresh rate
  alarmList: AlarmDetailsModel[] = [];
  locationAreaList: LocationAreaNameModel[];
  locationAreaDetails: LocationAreaDetail;
  selectedLocationArea: number;
  private routerSubscription!: Subscription;

  constructor(public auth: AuthService,
              public localData: LocalDataService,
              private common: CommonService,
              private locationService: LocationService,
              private router: Router,
              private alarmService: AlarmService, private popper: Popover) {}


  ngOnInit(): void {
    this.routerSubscription = this.router.events.pipe(
        filter(event => event instanceof NavigationEnd),
        distinctUntilChanged((prev: any, curr: any) => prev.url === curr.url)
    ).subscribe((event: NavigationEnd) => {
      if (event.url === '/card') {
        this.processAlarmsAndMarkers();
      }
    });
  }

  ngAfterViewInit(): void {
    this.processAlarmsAndMarkers();
  }

  private processAlarmsAndMarkers(): void {
    this.initLocationDetails();
  }

  private initLocationDetails(): void {
    this.locationAreaList = this.localData.get(LocalDataConstants.LOCATION_NAMES);
    this.locationAreaList = this.locationAreaList.sort((a,b) => {
      return b.name.localeCompare(a.name);
    }).reverse();
    this.selectedLocationArea = this.localData.get(LocalDataConstants.SELECTED_LOCATION);
    if (this.selectedLocationArea != null) {
      this.locationService.getLocationAreaDetails(
          this.locationAreaList[this.selectedLocationArea].id
      ).then((result: LocationAreaDetailResponse) => {
        this.locationAreaDetails = result.result;
        this.getLocationAlarms();
      });
    }

  }

  private getLocationAlarms() {
    console.log('refreshing at: ' + new Date());
    if (this.timer) {
      clearTimeout(this.timer);
    }
    this.timer = setTimeout(this.getLocationAlarms.bind(this), this.REFRESH_RATE);

    this.alarmList = [];
    this.alarmService.getLocationAlarms(this.locationAreaDetails).subscribe(
        // tslint:disable-next-line:no-shadowed-variable
        (result) => {
          this.alarmList = this.alarmService.getAlarmList(result, this.locationAreaDetails);
          this.localData.set(LocalDataConstants.ALARM_LIST, this.alarmList);
        }
    );

  }

  onBuildingPopupClick(event, id) {
    const building = this.locationAreaDetails.buildings.filter((x) => (x.id === id));
    if (building.length > 0) {
      const ref = this.popper.open<{  }>({
        content: CareFacilityDetailOverlayComponent,
        origin: event,
        data: {
          building: building[0]
        }
      });

      ref.afterClosed$.subscribe(res => {
        console.log(res);
      });
    } else {
      console.log('Error retrieving data');
    }
  }

  getCustomerNames(location: Building) {
    return this.common.getCustomerNames(location, true);
  }

  onAlarmPopupClicked(event) {
    const id = event._element.nativeElement.dataset.id;
    const alarm = this.alarmList.filter((x) => (x.location.id == id));
    const building = this.locationAreaDetails.buildings.filter((x) => (x.id == id));
    if (building.length > 0) {
      const ref = this.popper.open<{  }>({
        content: CareFacilityDetailOverlayComponent,
        origin: event,
        data: {
          building: building[0],
          alarm: alarm.length > 0 ? alarm[0] : [],
          locationAreaDetails: this.locationAreaDetails,
          locationId: id
        }
      });

      ref.afterClosed$.subscribe(res => {
        console.log(res);
      });
    } else {
      console.log('Error retrieving data');
    }
  }

  ngOnDestroy() {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }
}
