<div class="mat-snackbar">
	<div class="mat-snackbar-message">{{data.message}}</div>
	<div class="mat-snackbar-close">
		<button *ngIf="data.showCloseButton"
			mat-icon-button
			(click)="onDismiss()"
			color="warn">
			<mat-icon>clear</mat-icon>
		</button>
	</div>
</div>
