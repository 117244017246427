import {Component, HostListener, Input, OnInit, ViewChild} from '@angular/core';
import {MatSidenav} from '@angular/material/sidenav';
import {Router} from '@angular/router';
import {environment} from '../../environments/environment';
import {Popover} from '../core/popover/popover.service';
import {AccountOverlayComponent} from '../overlays/account/account-overlay.component';
import {UserService} from '../services/user.service';
import {ResponseModel} from '../core/lib/model/response.model';
import {LayoutUtilsService, MessageType} from '../core/lib/services/layout-utils.service';
import {NotificationsOverlayComponent} from '../overlays/notifications/notifications-overlay.component';
import {TranslateService} from '@ngx-translate/core';
import {AuthService} from '../core/auth/_services/auth.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {
  @ViewChild('sidenav', {static: false})
  sideNav: MatSidenav;

  title = 'Vesrat.io OneView';
  logoPath = environment.logoPath;
  windowHeight = 100;

  constructor(public auth: AuthService,
              private popper: Popover,
              private userService: UserService,
              private router: Router,
              private translateService: TranslateService,
              private layoutUtilsService: LayoutUtilsService) {

  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.windowHeight = 100;
  }

  ngOnInit() {
    this.windowHeight = 100;
  }

  toggleAccountPage(origin) {
    this.sideNav.toggle();
    this.auth.getUser$().toPromise().then((user) => {
      const ref = this.popper.open<{  }>({
        content: AccountOverlayComponent,
        origin,
        data: user
      });


      ref.afterClosed$.subscribe(res => {
        if (res.data) {
          this.updateUser(res);
        }
      });
    });
  }

  private updateUser(res) {
    this.userService.updateUser(res.data).then((result: ResponseModel) => {
      if (result.success) {
        const accountUpdated = this.translateService.instant('NOTIFICATIONS.ACCOUNT_UPDATED');
        const redirectingTxt = this.translateService.instant('NOTIFICATIONS.REDIRECTING');
        this.layoutUtilsService.showActionNotification(accountUpdated, MessageType.Update, 2000, true, false,0, 'top');
        setTimeout(() => {
          this.layoutUtilsService.showActionNotification(redirectingTxt, MessageType.Read, 4000, true, false,0, 'top');
        }, 2000 );
        setTimeout(() => this.auth.logout(), 4000);
      }
    });
  }

  toggleNotificationsPage(origin) {
    this.sideNav.toggle();
    this.auth.getUser$().toPromise().then((user) => {
      console.log(user);
      const ref = this.popper.open<{  }>({
        content: NotificationsOverlayComponent,
        origin,
        data: user.sub
      });


      ref.afterClosed$.subscribe(res => {
        if (res.data) {
          console.log(res.data);
          this.updateNotifications(res.data);
        }
      });
    });
  }

    private updateNotifications(userModel) {
        this.userService.updateAlarmNotifications(userModel).then((result: ResponseModel) => {
          if (result.success) {
            const notificationsUpdated = this.translateService.instant('NOTIFICATIONS.NOTIFICATIONS_UPDATED');
            this.layoutUtilsService.showActionNotification(notificationsUpdated, MessageType.Update, 2000, true, false,0, 'top');
          }
        });
    }
}
