<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #sidenav class="oneview-sidebar">
    <mat-card  class="expanded-panel-container profile-container">
      <mat-card-title>
        <img class="close-overlay-img" (click)="sidenav.toggle()" src="assets/images/cancel.svg"/>
      </mat-card-title>
      <mat-card-content style="clear: both;">
        <mat-list>
          <mat-list-item class="sidebar-menu-item sidebar-first-item" #accountPage (click)="toggleAccountPage(accountPage)">
            <div class="list-item-icn">
              <img class="person-img" [src]="'/assets/images/user.png'" alt="person icon" />
            </div>
            <p i18n class="sidebar-menu-item-text">{{'SIDEBAR.ACCOUNT' | translate }}</p>
          </mat-list-item>
          <mat-list-item class="sidebar-menu-item" #notifications (click)="toggleNotificationsPage(notifications)">
            <div class="list-item-icn">
              <img class="person-img" [src]="'/assets/images/notifications.svg'" alt="person icon" />
            </div>
            <p i18n class="sidebar-menu-item-text">{{'USER_ALARM_NOTIFICATION.NOTIFICATIONS' | translate }}</p>
          </mat-list-item>
          <mat-list-item class="sidebar-menu-item" (click)="auth.logout()">
            <div class="list-item-icn">
              <img class="person-img" [src]="'/assets/images/logout.svg'" alt="person icon" />
            </div>
              <p i18n class="sidebar-menu-item-text">{{'SIDEBAR.LOG_OUT' | translate }}</p>
          </mat-list-item>
        </mat-list>
      </mat-card-content>
    </mat-card>
  </mat-sidenav>
  <mat-sidenav-content>
    <div class="univ-container">
      <div class="left-content">
        <div class="topbar">
          <app-header [sideNavBar]="sidenav"></app-header>
        </div>
        <section class="map-content">
          <router-outlet></router-outlet>
        </section>
      </div>
      <div class="right-panel" [ngStyle]="{ 'height.vh': windowHeight }">
        <app-alarms-list></app-alarms-list>
        <!--<app-care-facility-list></app-care-facility-list>-->
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>


