import {
  Component,
  ElementRef, EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {SingleGraphDialogComponent} from '../../../overlays/single-graph-dialog/single-graph.dialog.component';
import {AlarmDismissDialogComponent} from '../../../overlays/alarm-dismiss-dialog/alarm-dismiss.dialog.component';
import {AlarmDetailsModel} from '../../../models/alarm-details.model';
import {TranslateService} from '@ngx-translate/core';
import {HttpService} from '../../../core/lib/services/http.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Label} from "ng2-charts";
import {CommonService} from '../../../services/common.service';
import {Site} from '../../../AdminApi';
import {ChartColor, ChartOptions, ChartType} from "chart.js";
import {GraphHelper} from "../../../helpers/graph.helper";
import {Color} from "ng2-charts/lib/color";


@Component({
  selector: 'app-graph',
  templateUrl: './graph.component.html',
  styleUrls: ['./graph.component.scss']
})
export class GraphComponent implements OnInit, OnChanges {

  @Output('alarmDismissedEvent') alarmDismissedEvent = new EventEmitter<boolean>();
  @Input('sensor') sensor;
  @Input('keyVal') keyVal;
  @Input('selectedSite') selectedSite;
  @Input('selectedFloor') selectedFloor;
  @Input('building') building;
  @Input('sensorGraphs') sensorGraphs;
  @Input('customer') customers;
  @Input('alarmData') alarmData :AlarmDetailsModel;
  @Input('graphHelper') graphHelper : GraphHelper;

  @ViewChild('headerTitle', { static: true }) headerTitle: ElementRef;

  meanVal: string = '';
  maxVal: string = '';
  minVal: string = '';
  loading: boolean = true;
  noResults: boolean = false;
  form: FormGroup;
  sensorKeyHeaderTitle = '';
  dateSelectionError: boolean = false;
  chartType: ChartType = 'line';
  dateSelectionErrorText: string = '';
  options: ChartOptions;
  datasets = [];
  chartLegend = false;
  chartPlugins = [];
  labels: Label[] = [];
  sensorData = [];


  isNurse = true;
  private title: string = '';

  constructor(private dialog: MatDialog, private translateService: TranslateService,
              private common: CommonService,
              private fb: FormBuilder,
              private renderer: Renderer2,
              private http: HttpService,
             ) {
  }

  ngOnInit(): void {
    this.initDateFilters();
  }


  openZoomGraph (key: string, sensor: string) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '90vw';
    dialogConfig.height = '90vh';
    dialogConfig.maxHeight = '90vh';
    dialogConfig.maxWidth = '90vw';
    dialogConfig.panelClass = 'white-background';
    dialogConfig.position = { top: '0' };
    dialogConfig.data = {
      sensor: sensor,
      key: key,
      selectedSite: this.selectedSite,
      selectedFloor: this.selectedFloor,
      building: this.building,
      sensorGraphs: this.sensorGraphs,
      customers: this.customers,
      graphHelper: this.graphHelper
    };

    this.dialog.open(SingleGraphDialogComponent, dialogConfig);
  }

  openDismissDialog(key: string, sensor: string) {
    const alarmRes = this.alarmData.alarmList.filter(alarm => {
      const alarmMeasurementType = alarm.measurementType.toUpperCase() === 'MOISTURE' ? 'HUMIDITY' : (alarm.measurementType.toUpperCase() === 'PERSONWEIGHT') ? 'WEIGHTAVG' : alarm.measurementType.toUpperCase();
      return alarmMeasurementType == key.toUpperCase() && sensor === alarm.devId;
    });
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '40vw';
    dialogConfig.data = { model: alarmRes[0] };
    dialogConfig.panelClass = 'dismiss-alarm';

    const dismissAlarm = this.dialog.open(AlarmDismissDialogComponent, dialogConfig);

    dismissAlarm.afterClosed().subscribe(
        val => {
          if (val != undefined && val != null) {
            this.dismissAlarm(val, val.comment);
          }
        }
    );
  }

  dismissAlarm(alarmItem, dismissComment: string) {
    const data = JSON.stringify({
      alarmName: alarmItem.alarmName,
      time: alarmItem.receivedTime,
      // import jquery here or use some document query...
      dismissComment,
    });
    const url = `/dismiss/${alarmItem.devId}`;
    return this.http.post(url, data).then(
        (res) => {
          this.afterAlarmDismiss();
        },
        (error) => {
          alert(`Could not dismiss alarm, got error: ${error}`);
        }
    );
  }

  filterByDate(k: any, sensor: string) {
    const fromDate = this.form.controls.fromDate.value;
    const toDate = this.form.controls.toDate.value;
    if ((fromDate == undefined || fromDate == '') || (toDate == undefined || toDate == '')) {
      var datesToBeSelected = this.translateService.instant('CARE_FACILITY.DATES_NEED_TO_BE_SELECTED');
      this.dateSelectionErrorText = datesToBeSelected;
      this.dateSelectionError = true;
      return false;
    } else {
      this.dateSelectionError = false;
    }
    const toDateObj = new Date(toDate);
    toDateObj.setHours(23, 59, 59, 59);
    const fromDateObj = new Date(fromDate);
    fromDateObj.setHours(0, 0, 0,0);
    const fromDateTime = fromDateObj.getTime();
    const toDateTime = toDateObj.getTime();

    if (fromDateTime > toDateTime) {
      var dateError = this.translateService.instant('CARE_FACILITY.FROM_LARGER_THAN_TO');
      this.dateSelectionErrorText = dateError;
      this.dateSelectionError = true;
      return false;
    } else {
      this.dateSelectionError = false;
    }

    this.form.controls.selectHoursFilter.setValue('');

    this.noResults = false;
    this.graphHelper = new GraphHelper();
    this.graphHelper.filteringK = k;
    this.graphHelper.filteringSensor = sensor;
    const hours = Math.abs(fromDateTime - toDateTime) / 36e5;
    this.graphHelper.HOURS = hours;
    this.graphHelper.checkGraphHours();
    this.graphHelper.langValue = this.translateService.currentLang;
    this.showDashboard(fromDateTime, toDateTime);
  }


  async showDashboard(fromTime = null, toTime = null): Promise<void> {
    // tslint:disable-next-line:triple-equals
    const floor = this.building.floors.filter((x) => (x.id == this.selectedFloor))[0];

    // tslint:disable-next-line:triple-equals
    const site = floor.sites.filter((x) => (x.id == this.selectedSite))[0];
    this.graphHelper.accessibleSensors = site.sensors.map((sensor) => sensor.devId);
    this.graphHelper.locationName = site.name;
    site.sensors =  site.sensors.sort((a,b) => {
      return b.comments.localeCompare(a.comments);
    }).reverse();
    this.graphHelper.sensorComments = [];
    for (const sensor of site.sensors) {
      const comment = { id: sensor.devId, comment: sensor.comments };
      this.graphHelper.sensorComments.push(comment);
    }

    this.loading = true;
    this.noResults = false;
    this.getSensorData(fromTime, toTime, this.graphHelper.filteringSensor, site);
  }

  private getSensorData(fromTime: any, toTime: any, sensor: string, site: Site) {
    if (fromTime && toTime) {
      const listPrefix = `/archive/${site.id}/${sensor}/from/${fromTime}/to/${toTime}`;
      this.http.postRequest(listPrefix, {}, null).subscribe((result) => {
        this.processResults(sensor, result, fromTime, fromTime, toTime);
      }, this.graphHelper.showError.bind(this));
    } else {
      const listPrefix = `/archive/${site.id}/${sensor}/from/`;
      const time = this.common.unixUTCSnappedSince(fromTime);
      this.http.postRequest(listPrefix + time, {}, null).subscribe((result) => {
        this.processResults(sensor, result, time, fromTime, toTime);
      }, this.graphHelper.showError.bind(this));
    }
  }

  processResults(sensor: string, data: any, minTime: number, fromTime: number, toTime: number): void {
    if (data && data.length > 0) {
      this.noResults = false;
      if (this.graphHelper.processDataItems(data, minTime, sensor)) {
        this.initGraph(fromTime, toTime);
      }
    } else {
      this.noResults = true;
      this.minVal = 'N/A';
      this.maxVal = 'N/A';
      this.meanVal = 'N/A';
      this.loading = false;

    }
  }


  changeTimeSelection(event, k, sensor) {
    // tslint:disable-next-line:triple-equals
    if (event.val != '') {
      this.form.controls.fromDate.setValue('');
      this.form.controls.toDate.setValue('');
      this.graphHelper = new GraphHelper();
      this.graphHelper.filteringK = k;
      this.graphHelper.filteringSensor = sensor;

      this.graphHelper.HOURS = event.value;
      this.graphHelper.checkGraphHours();


      this.graphHelper.langValue = this.translateService.currentLang;
      const time = this.common.unixUTCSnappedSince(this.graphHelper.HOURS);
      const toDateObj = new Date();
      const toDateTime = toDateObj.getTime();
      this.showDashboard(time, toDateTime);
    }
  }

  private attachGraphCanvas(k, sensor, sensorData, tempKey, measurementName, installationPlace, fromTime: number, toTime: number) {

    this.loading = false;
    this.noResults = false;
    this.options = this.graphHelper.getGraphOptions(sensorData[k], sensor, tempKey, true, this.translateService.currentLang);
    if (fromTime == null && toTime == null) {
      fromTime = this.common.unixUTCSnappedSince(this.graphHelper.HOURS);
      const toDateObj = new Date();
      toTime = toDateObj.getTime();
     }
    const fromDateLabel = new Date(fromTime).toLocaleDateString('fi-FI');
    const toDateLabel = new Date(toTime).toLocaleDateString('fi-FI');
    const fromTimeLabel = new Date(fromTime).toLocaleTimeString('fi-FI');
    const toTimeLabel = new Date(toTime).toLocaleTimeString('fi-FI');
    let customers = '';
    this.customers.forEach((customer, index) => customers += (index == 0 ? customer.name : ',' + customer.name));
    this.title = measurementName + " - " +
        installationPlace +
        " | "
        + fromDateLabel + " " + fromTimeLabel
        + " - "
        + toDateLabel + " " + toTimeLabel +
        " | " +
        customers;
    this.options.title = {
      text: this.title,
      display: true,
      fontSize: 11,
    }

    if (k == 'durationOnScale') {
      var minValue = 'N/A';
      var maxValue = 'N/A';
      var meanValue = 'N/A';

      var minDuration =this.graphHelper.minValue.toFixed(1);
      var minDurationSplit = minDuration.split('.');
      if (minDurationSplit.length > 1) {
        minValue = minDurationSplit[0] + ' min ' + parseFloat('0.'+minDurationSplit[1])  * 60 + ' s';
      } else {
        minValue =  minDurationSplit[0] + ' min';
      }

      var maxDuration =this.graphHelper.maxValue.toFixed(1);
      var maxDurationSplit = maxDuration.split('.');
      if (maxDurationSplit.length > 1) {
        maxValue = maxDurationSplit[0] + ' min ' + parseFloat('0.'+maxDurationSplit[1])  * 60 + ' s';
      } else {
        maxValue =  maxDurationSplit[0] + ' min';
      }

      var meanDuration = this.graphHelper.meanValue.toFixed(1);
      var meanDurationSplit = meanDuration.split('.');
      if (meanDurationSplit.length > 1) {
        meanValue = meanDurationSplit[0] + ' min ' + parseFloat('0.'+meanDurationSplit[1])  * 60 + ' s';
      } else {
        meanValue =  meanDurationSplit[0] + ' min';
      }

      this.minVal = minValue;
      this.maxVal = maxValue;
      this.meanVal = meanValue;
    } else {
      this.minVal = measurementName + ': ' + this.graphHelper.minValue;
      this.meanVal = measurementName + ': ' + this.graphHelper.meanValue;
      this.maxVal = measurementName + ': ' + this.graphHelper.maxValue;
    }


    this.datasets = [this.graphHelper.getDataSpec(tempKey, this.graphHelper.values, measurementName)];
    this.labels = this.graphHelper.labels;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.initDateFilters();
    this.initGraph(null, null);
  }

  translateAlarmName(alarmName: string) {
    return this.translateService.instant('ALARMS.' + alarmName);
  }

  translateAlarmTimeLabel() {
    return this.translateService.instant('ALARM_DETAILS.TIME');
  }


  private initGraph(fromTime: number, toTime: number) {
    this.sensorData = this.graphHelper.dataItems[this.sensor];
    const k = this.keyVal.key;
    if (this.sensorData && this.sensorData.hasOwnProperty(k)) {
      let tempKey = k === 'temp' ? 'temperature' : k;
      tempKey = tempKey.toUpperCase() === 'MOISTURE' ? 'humidity' : (tempKey.toUpperCase() === 'PERSONWEIGHT') ? 'weightAvg' : tempKey;

      this.graphHelper.labels = [];
      this.graphHelper.values = [];

      const measurementName = this.common.translateMeasurementName(k);

      const installationPlace = this.graphHelper.sensorComments.filter((place) => place.id == this.sensor)[0];
      if (this.alarmData) {
        const alarmRes = this.alarmData.alarmList.filter(alarm => {
          const alarmMeasurementType = alarm.measurementType.toUpperCase() === 'MOISTURE' ? 'HUMIDITY' : (alarm.measurementType.toUpperCase() === 'PERSONWEIGHT') ? 'WEIGHTAVG' : alarm.measurementType.toUpperCase();
          return alarmMeasurementType == k.toUpperCase() && this.sensor === alarm.devId;
        });

        if (alarmRes.length > 0) {
          var alarmDate = new Date(alarmRes[0].receivedTime).toLocaleString([], { hour12: false});
          if (this.graphHelper.isAddozDevice(alarmRes[0].alarmName) || this.graphHelper.isVayyarDevice(alarmRes[0].measurementType, [alarmRes[0].alarmName])){
            if (alarmRes[0].extras) {
              //<sqrt(abs(x)^2+abs(y)^2)>
              var distance = Math.sqrt(
                  Math.abs(
                      Math.pow((alarmRes[0].extras["x_cm"] * 0.01), 2)
                  ) + Math.abs(
                  Math.pow((alarmRes[0].extras["y_cm"] * 0.01), 2)
                  )
              );
            }
            this.sensorKeyHeaderTitle = '<img class="alarm-img" src="assets/images/icons/alarms/lvl-' + alarmRes[0].severity + '/' + alarmRes[0].icon + '.svg"' + 'alt="measurement type icon" />' +
                '<p class="alarm-header-info">' + this.translateAlarmName(alarmRes[0].alarmName) + ' (' + this.translateAlarmTimeLabel() + ' ' + alarmDate+ ')' + ' | </p><p style="padding-top: 0.1vw;">' + installationPlace.comment +  ' | ' + this.translate('distance') + distance.toFixed(2)  + this.translate('distance_metric') + '</p>';
          } else {
            this.sensorKeyHeaderTitle =   '<img class="alarm-img" src="assets/images/icons/alarms/lvl-' + alarmRes[0].severity + '/' + alarmRes[0].icon + '.svg"' + 'alt="measurement type icon" />' +
                '<p class="alarm-header-info">' + this.translateAlarmName(alarmRes[0].alarmName) + ' (' + alarmRes[0].filters[0].value + ', ' + this.translateAlarmTimeLabel() + ' ' + alarmDate+ ')' + ' | </p><p style="padding-top: 0.1vw;">' + measurementName + ' - ' + installationPlace.comment + '</p>';
          }


          this.renderer.addClass(this.headerTitle.nativeElement, 'alarm-lvl-' + alarmRes[0].severity);
        } else {
          this.sensorKeyHeaderTitle = measurementName + ' - ' + installationPlace.comment;
        }

      } else {
        this.sensorKeyHeaderTitle = measurementName + ' - ' + installationPlace.comment;
      }

      this.loading = true;
      this.noResults = false;
      this.attachGraphCanvas(k, this.sensor, this.sensorData, tempKey, measurementName, installationPlace.comment, fromTime, toTime);

    } else {
      if (this.graphHelper.filteringSensor != null && this.graphHelper.filteringK != null) {
        if (this.graphHelper.filteringSensor == this.sensor && this.graphHelper.filteringK == k) {
          this.loading = false;
          this.noResults = true;
        }
      }
    }
  }

  private initDateFilters() {
    this.form = this.fb.group({
      fromDate: ['', Validators.required],
      toDate: ['', Validators.required],
      selectHoursFilter: [this.graphHelper.isHenkausDevice(this.keyVal.key, null, true) ? '0.15' : '48']
    });
  }

  private afterAlarmDismiss() {
    this.alarmDismissedEvent.emit(true);
  }

  private translate(translateKey: string) {
    return this.translateService.instant('ALARMS.' + translateKey);
  }


  downloadCanvas(key, sensor) {
    var canvasId = key + "_" + sensor;
    var href = document.createElement('a');
    // get the canvas
    var canvas = document.querySelector('canvas#' + canvasId) as any;
    var ctx = canvas.getContext('2d');

    // set the ctx to draw beneath your current content
        ctx.globalCompositeOperation = 'destination-over';

    // set the fill color to white
        ctx.fillStyle = 'white';

    // apply fill starting from point (0,0) to point (canvas.width,canvas.height)
    // these two points are the top left and the bottom right of the canvas
        ctx.fillRect(0, 0, canvas.width+200, canvas.height+200);
    href.href = canvas.toDataURL('image/jpg');
    let text = this.title as any;
    href.download = text.replaceAll(' ','').replaceAll('|', '_') + ".jpg";
    href.click();
  }
}
