<div class="popover">
    <ng-container [ngSwitch]="renderMethod">
        <div *ngSwitchCase="'text'" [innerHTML]="content"></div>
        <ng-container *ngSwitchCase="'template'">
            <ng-container *ngTemplateOutlet="content; context: context"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'component'">
            <ng-container *ngComponentOutlet="content"></ng-container>
        </ng-container>
    </ng-container>
</div>