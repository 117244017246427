import {BaseFtModel} from '../core/lib/model/base-ft.model';

export class UserAlarmNotificationModel extends BaseFtModel{
    active: boolean;
    service: number;
    mon: number;
    tue: number;
    wed: number;
    thur: number;
    fri: number;
    sat: number;
    sun: number;
    fromTime: string;
    toTime: string;
}
