import { NgModule } from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {ErrorComponent} from './error.component';
import {CommonModule} from '@angular/common';
import {CoreModule} from '@angular/flex-layout';
import {RouterModule} from '@angular/router';
import {Error1Component} from "./error1/error1.component";

@NgModule({
  declarations: [
    ErrorComponent,
    Error1Component,
  ],
  imports: [
    CommonModule,
    CoreModule,
    RouterModule.forChild([
      {
        path: 'error',
        component: ErrorComponent,
        children: [
          {
            path: '',
            component: Error1Component,
          },
        ],
      },
    ]),
    TranslateModule,
  ],
})
export class ErrorModule {}
