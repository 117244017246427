import {AfterViewInit, Component, OnInit, HostListener, ViewEncapsulation, TemplateRef} from '@angular/core';
import * as L from 'leaflet';
import 'leaflet-realtime';
import { LocalDataService } from 'src/app/services/local-data.service';
import { NavigationEnd, Router } from '@angular/router';
import {AlarmService} from '../../services/alarm.service';
import {AlarmDetailsModel} from '../../models/alarm-details.model';
import {Popover} from '../../core/popover/popover.service';
import {AuthService} from 'src/app/core/auth/_services/auth.service';
import {LocalDataConstants} from '../../core/lib/utility/local-data.constants';
import {LocationAreaNameModel} from '../../models/location-area-name.model';
import {LocationService} from '../../services/location.service';
import {CareFacilityDetailOverlayComponent} from '../../overlays/care-facility-detail/care-facility-detail-overlay.component';
import {AlarmListService} from "../../services/alarm-list.service";
import {BuildingTypesEnum} from "../../core/lib/enums/building-types.enum";
import {environment} from "../../../environments/environment";
import {TrackerHistoryOverlayComponent} from "../../overlays/tracker-history/tracker-history-overlay.component";
import {LocationAreaDetail, LocationAreaDetailResponse} from '../../AdminApi';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MapComponent implements OnInit, AfterViewInit {
  baseLayers: any;
  markerLayer: any;
  realtimeLayer: any;
  popupsLayer: any;
  timer: any;
  REFRESH_RATE = 30 * 1000; // 30 sec refresh rate
  private map: any;
  private realtime: any;
  private baseControl: any;
  alarmList: AlarmDetailsModel[];
  locationAreaList: LocationAreaNameModel[];
  locationAreaDetails: LocationAreaDetail;
  selectedLocationArea: number;

  constructor(public auth: AuthService, private alarmListService: AlarmListService, public localData: LocalDataService, private router: Router,
              private alarmService: AlarmService, private locationService: LocationService, private popper: Popover) {}

  ngOnInit(): void {
  }


  ngAfterViewInit(): void {
    this.initMap();
    this.panIfNeeded();
    this.initLocationDetails(true);
    this.router.events.subscribe(this.routeChange.bind(this));
  }

  private routeChange(val: any) {
    if (val instanceof NavigationEnd && val.urlAfterRedirects.indexOf('/map') !== -1) {
      this.panIfNeeded();
      this.initLocationDetails(true);
    }
  }

  private panIfNeeded() {
    const obj = this.decodePathObj();
    if (obj && obj.lat) {
      this.zoomTo(obj.lat, obj.lon, obj.zoom);
    }
  }

  private initMap(): void {
    const tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 19,
      attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
    });

    this.baseLayers = { OpenStreetmap: tiles };

    this.map = L.map('map', {
      center: [61.0584201, 28.1860792],
      zoom: 13,
      zoomControl: true,
      layers: [tiles],
    });
    this.map.zoomControl.setPosition('topright');

    this.map.on('moveend', () => {
      console.log(this.map.getCenter().toString());
    });
  }

  private initLocationDetails(initYepzon: boolean): void {
    this.locationAreaList = this.localData.get(LocalDataConstants.LOCATION_NAMES);
    this.locationAreaList = this.locationAreaList.sort((a,b) => {
      return b.name.localeCompare(a.name);
    }).reverse();
    this.selectedLocationArea = this.localData.get(LocalDataConstants.SELECTED_LOCATION);
    if (this.selectedLocationArea != null) {
      this.locationService.getLocationAreaDetails(
          this.locationAreaList[this.selectedLocationArea].id
      ).then((result: LocationAreaDetailResponse) => {
        this.locationAreaDetails = result.result;
        this.getLocationAlarms(initYepzon);
        this.alarmListService.currentMessage.subscribe(data => {
          this.alarmList = data;
          this.addMarkers(false);
        });
      });
    }
  }

  private getLocationAlarms(initYepzon: boolean = false) {
    console.log('refreshing at: ' + new Date());
    if (this.timer) {
      clearTimeout(this.timer);
    }
    this.timer = setTimeout(this.getLocationAlarms.bind(this), this.REFRESH_RATE);

    this.alarmList = [];
    this.alarmService.getLocationAlarms(this.locationAreaDetails).subscribe(
        // tslint:disable-next-line:no-shadowed-variable
        (result) => {
          this.alarmList = this.alarmService.getAlarmList(result, this.locationAreaDetails);
          this.localData.set(LocalDataConstants.ALARM_LIST, this.alarmList);
          this.alarmListService.changeMessage(this.alarmList);
          this.addMarkers(initYepzon);
        }
    );

  }

  private addMarkers(initYepzon: boolean = true): void {
    if (this.markerLayer) {
      this.map.removeLayer(this.markerLayer);
    }
    if (this.popupsLayer) {
      this.map.removeLayer(this.popupsLayer);
    }
    if (initYepzon) {
      if (this.realtimeLayer) {
        this.map.removeLayer(this.realtimeLayer);
      }
    }
    this.realtimeLayer = L.layerGroup();

    this.markerLayer = L.layerGroup();
    this.popupsLayer = L.layerGroup();


    for (const location of this.locationAreaDetails.buildings) {
      if (location.buildingType != BuildingTypesEnum.YEPZON_DEVICE) {
        const coords = { lat: location.latitude, lon: location.longitude };

        const name = location.name;
        if (coords) {
          let iconUrl = '';
          if (BuildingTypesEnum.HOME_CARE == location.buildingType) {
            iconUrl = '/assets/images/nf_home_purple_white.svg';
          } else {
            iconUrl = '/assets/images/nf_care_home_purple_white.svg';
          }

          const icon = L.divIcon({
            html: '<img src="' + iconUrl + '" style="width:3vw;height:4.5vh; margin-left:-0.8vw; margin-top:-0.8vw"/>',
          });
          const alarms = this.localData.get(LocalDataConstants.ALARM_LIST) != undefined ? this.localData.get(LocalDataConstants.ALARM_LIST).filter(alarm => alarm.location.id === location.id): [];

          let addMarker = true;
          if (alarms.length > 0 && alarms[0].alarmList.length > 0) {
            addMarker = false;
            const pinnedAlarm = alarms[0].alarmList[0];
            const point = L.point(0, 0);
            const container = L.DomUtil.create('div');
            const content = L.DomUtil.create('img', 'alarm-image', container);
            content.height = '70';
            content.width = '70';
            content.setAttribute('data-id', alarms[0].location.id);


            if (alarms[0].alarmList.length > 1) {
              content.src = 'assets/images/icons/alarms/lvl-' + pinnedAlarm.severity + '/general_alarm.svg';
              for (const [index, alarmModel] of alarms[0].alarmList.entries()) {
                if (index <= 6) {
                  const alarmIconClass = this.alarmService.getAlarmIconMapViewClass(index);
                  const alarmIcon = L.DomUtil.create('p', 'map-alarm-icon ' + alarmIconClass, container);

                  const imageUrl = 'assets/images/icons/alarms/lvl-' + alarmModel.severity + '/' + alarmModel.icon + '.svg';
                  alarmIcon.style.background = "url('" + imageUrl + "') no-repeat";
                  alarmIcon.style.backgroundSize = '100%';
                }
              }
            } else {
              content.src = 'assets/images/icons/alarms/lvl-' + pinnedAlarm.severity + '/' + pinnedAlarm.icon + '.svg';
            }

            L.DomEvent.addListener(container, 'click', this.onAlarmPopupClicked, this);

            const popupCords = new L.LatLng(coords.lat, coords.lon);

            const popup = new L.Popup({closeButton: false, autoClose: false, closeOnEscapeKey: false, closeOnClick: null,
              className: 'alarms-popups alarm-lvl-' + pinnedAlarm.severity, autoPanPadding: point});
            popup.setLatLng(popupCords);
            popup.setContent(container);
            popup.openPopup();

            popup.addTo(this.popupsLayer);
          }
          if (addMarker) {
            const marker = L.marker([coords.lat, coords.lon], { icon });
            marker.data = {id: location.id};

            marker.addTo(this.markerLayer);
            L.DomEvent.addListener(marker, 'click', this.onBuildingPopupClick, this);
          }
        }
      } else {
        if (initYepzon) {
          const self = this;
          const tagId = location.floors[0].sites[0].sensors[0].devId;
          this.auth.getTokenSilently$().subscribe(token => {
            this.realtime = L.realtime({
              url: `${environment.backendUri}/api/v2/yepzon/tags/${tagId}/`,
              crossOrigin: true,
              headers: {'Authorization': `Bearer ${token}`},
              type: 'json'
            }, {
              interval: 300 * 1000,
              pointToLayer: function (feature, latlng) {
                return L.marker(latlng, {
                  'icon': L.icon({
                    iconUrl: '/assets/images/icons/yepzon_logo.png',
                    //shadowUrl: 'https://image.shutterstock.com/image-vector/little-puppy-icon-simple-cute-260nw-450673921.jpg',
                    iconSize:     [30, 30], // size of the icon
                    //shadowSize:   [50, 64], // size of the shadow
                    //iconAnchor:   [22, 94], // point of the icon which will correspond to marker's location
                    // shadowAnchor: [4, 62],  // the same for the shadow
                    //popupAnchor:  [-3, -76] // point from which the popup should open relative to the iconAnchor
                  }),
                  data: location.id
                }).on('click', (e) => self.onRealTimeTrackerClick(e));
              }
            }).addTo(this.realtimeLayer);
          });
          this.realtimeLayer.addTo(this.map);
        }
      }
    }

    //L.DomEvent.addListener(this.realtimeLayer, 'click', this.onBuildingPopupClick, this);
    var map = this.map;
    //var realtime = this.realtime;

   /* realtime.on('click', function(){
      console.log("clicked here");
    });
    realtime.on('update', function() {
      console.log("updaing here");
      map.fitBounds(realtime.getBounds(), {maxZoom: 3});
    });*/

    
    this.markerLayer.addTo(this.map);
    this.popupsLayer.addTo(this.map);

    const overlays = {
      Sensors: this.markerLayer,
      Alarms: this.popupsLayer,
      Yepzon: this.realtimeLayer
    };
    // remove the current control panel
    if (this.baseControl) {
      this.map.removeControl(this.baseControl);
    }
    // replace it
    this.baseControl = L.control.layers(this.baseLayers, overlays).addTo(this.map);
  }

  onAlarmPopupClicked(event) {
    let id = event.target.getAttribute('data-id');
    const alarm = this.alarmList.filter((x) => (x.location.id == id));
    const building = this.locationAreaDetails.buildings.filter((x) => (x.id == id));
    if (building.length > 0) {
      const ref = this.popper.open<{  }>({
        content: CareFacilityDetailOverlayComponent,
        origin: event,
        data: {
          building: building[0],
          alarm: alarm.length > 0 ? alarm[0] : [],
          locationAreaDetails: this.locationAreaDetails,
          locationId: id
        }
      });

      ref.afterClosed$.subscribe(res => {
        this.initLocationDetails(false);
      });
    } else {
      console.log('Error retrieving data');
    }
  }

  onRealTimeTrackerClick(event) {
    const props = event.target.feature.properties.props;
    const id = event.target.defaultOptions.data;
    const tracker = this.locationAreaDetails.buildings.filter((x) => (x.id === id));
    if (tracker.length > 0) {
      const ref = this.popper.open<{  }>({
        content: TrackerHistoryOverlayComponent,
        origin: event,
        data: {
          tracker: tracker[0]
        }
      });

      ref.afterClosed$.subscribe(res => {
        console.log(res);
      });
    } else {
      console.log('Error retreiving data');
    }
  }

  onBuildingPopupClick(event) {
    let id = event.target.data.id;
    const building = this.locationAreaDetails.buildings.filter((x) => (x.id === id));
    if (building.length > 0) {
      const ref = this.popper.open<{  }>({
        content: CareFacilityDetailOverlayComponent,
        origin: event,
        data: {
          building: building[0]
        }
      });

      ref.afterClosed$.subscribe(res => {
        console.log(res);
      });
    } else {
      console.log('Error retreiving data');
    }
  }

  public zoomTo(lat: number, lon: number, zoom: number) {
    // tslint:disable-next-line:radix
    this.map.flyTo([lat, lon], parseInt(zoom.toString()), {
      animate: false
    });
  }

  public decodePathObj() {
    const pathItems = location.pathname.split(';');
    if (pathItems.length > 1) {
      const pathObj: any = {};
      for (const item of pathItems) {
        const arr = item.split('=');
        if (arr.length > 1) {
          const vals = arr[1].split(',');
          pathObj[arr[0]] = arr[1];
        }
      }
      // access=A81758FFFE140209,70B3D57050000D6E,A81758FFFE0357F0,A81758FFFE140202
      if (pathObj.access) {
        pathObj.access = pathObj.access.sort();
      }
      pathObj.name = decodeURIComponent(pathObj.name);

      return pathObj;
    } else {
      this.locationAreaList = this.localData.get(LocalDataConstants.LOCATION_NAMES);
      this.locationAreaList = this.locationAreaList.sort((a,b) => {
        return b.name.localeCompare(a.name);
      }).reverse();
      this.selectedLocationArea = this.localData.get(LocalDataConstants.SELECTED_LOCATION);
      const pathObj: any = {};
      if (this.selectedLocationArea != null) {
        pathObj.name = this.locationAreaList[this.selectedLocationArea].name;
        pathObj.lat = this.locationAreaList[this.selectedLocationArea].latitude;
        pathObj.lon = this.locationAreaList[this.selectedLocationArea].longitude;
      }
      pathObj.zoom = 12;
      return pathObj;
    }
  }

}
