import {BaseFtModel} from '../core/lib/model/base-ft.model';
import {UserModel} from './user.model';
import {UserAlarmNotificationModel} from './user-alarm-notification.model';
import {RoleModel} from './role.model';

export class UserDataModel extends BaseFtModel{
    email: string;
    phoneNumber: string;
    authId: string;
    address: string;
    password: string;
    confirmPassword: string;
    roles: RoleModel[];
    notes: string;
    relatives: UserModel[];
    userAlarmNotification: UserAlarmNotificationModel[];
    supervisors: UserModel[];
}
