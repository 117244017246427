import {Injectable} from '@angular/core';
import {FTBaseService} from '../core/lib/services/ft-base.service';
import {HttpService} from '../core/lib/services/http.service';

@Injectable()
export class UserService extends FTBaseService {
    serviceApi = '/user';
    organisationTypeApi = '/type';
    emailCheckApi = '/email/exists';
    updateAlarmNotificationsApi = '/alarm-notifications/update';

    constructor(httpService: HttpService) {
        super(httpService);
    }

    updateUser(data) {
        console.log('user to be updated: ' , data);
        return this.httpService.put(this.serviceApi + '/' + data.id, data);
    }

    checkEmail(email: string, userId: number) {
        return this.httpService.post(this.serviceApi + this.emailCheckApi, {  email, userId });
    }

    getUserByAuthId(authId: string) {
        return this.httpService.get(encodeURI(this.serviceApi +  '/details/' + authId));
    }

    updateAlarmNotifications(model: any) {
        return this.httpService.post(this.serviceApi + this.updateAlarmNotificationsApi, model);
    }

    getOrganisationType() {
        return this.httpService.get(this.serviceApi + this.organisationTypeApi);
    }
}
