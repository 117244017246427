import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class AlarmListService {

    private messageSource = new BehaviorSubject([]);
    currentMessage = this.messageSource.asObservable();

    constructor() { }

    changeMessage(alarmList: any[]) {
        this.messageSource.next(alarmList);
    }

}
