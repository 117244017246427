<h2 class="alarms-header" style="font-size:0.9vw">{{ "ALARM_DETAILS.ALARMS" | translate }}</h2>
<div id="alarms-container" [ngStyle]="{ 'height.vh': targetHeight }">
  <div *ngIf="alarmListLoading; else resultsTemplate">
    <app-loader></app-loader>
  </div>
  <ng-template #resultsTemplate>
    <div *ngIf="!hasAlarms; else locationList">
      <p style="text-align: center; color: lightgray;font-size: 0.9vw;">{{ 'SEVERITY.NO_ALARMS' | translate }}</p>
    </div>
    <ng-template #locationList>
      <ul class="locations-list">
        <li *ngFor="let item of alarmList; let locationIndex = index;" class="locationItem severity{{ item.severity }}" >
          <!--<p class="lastAlarms severity{{ item.severity }}" i18n>active alarms: {{ item.alarmCount }}</p>-->
          <ul class="alarms-list" *ngIf="item.alarmList.length > 0">
            <!--<li #listItem *ngIf="item.alarmList.length == 0; else alarmsTemplate" (click)="openCareFacilityDetailsOverlay(listItem, item, locationIndex)">
              <div class="alarmItem severity{{ item.severity }}">
                <div class="alarm-list-item-content">
                  <div class="alarm-list-logo-container">
                   <img class="location-img " [src]="'assets/images/location_icon.png'" alt="measurement type icon" />
                  </div>
                  <div class="alarmDetail alarm-list-title-container ">
                    <p class="alarmName ">
                      <a>{{ item.location.name.length > 20 ? item.location.name.substring(0, 19) + "..." : item.location.name }}</a>
                    </p>
                    <p class="hoitaja">
                      Hoitaja 1
                    </p>
                  </div>
                </div>
              </div>
            </li>-->
            <!-- <ng-template  #alarmsTemplate>-->
            <li class="clickable-alarms" #listItem (click)="onAlarmPopupClicked(listItem, item)">
              <div class="alarmItem severity{{ item.alarmList[0].severity }}">
                <div class="alarm-list-item-content">
                  <div class="alarm-list-logo-container">
                    <img class="alarm-img" [src]="'assets/images/icons/alarms/lvl-' + item.alarmList[0].severity + '/' + item.alarmList[0].icon + '.svg'" alt="measurement type icon" />
                  </div>
                  <div class="alarmDetail alarm-list-title-container">
                    <p class="alarmName">
                      {{ item.location.name.length > 20 ? item.location.name.substring(0, 19) + "..." : item.location.name }}
                    </p>
                    <p class="hoitaja">
                      {{ item.location | getCustomerNameForBuilding }}
                    </p>
                  </div>
                </div>
              </div>
            </li>
            <!--  </ng-template>-->
          </ul>
        </li>
      </ul>
    </ng-template>

  </ng-template>
  <div class="minilogodiv">
    <img class="logo vesratio-footer" src="/assets/images/Hoivita_logo_sand.svg" alt="cloud service by vesratio" />
  </div>
</div>
