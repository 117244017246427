import {Injectable} from '@angular/core';
import {FTBaseService} from '../core/lib/services/ft-base.service';
import {HttpService} from '../core/lib/services/http.service';

@Injectable()
export class LocationService extends FTBaseService {

    serviceApi = '/location-area';
    detailsApi = '/details';


    constructor(httpService: HttpService) {
        super(httpService);
    }

    getLocationAreaNames() {
        return this.httpService.get(this.serviceApi + '/list');
    }

    getLocationAreaDetails(id: number) {
        return this.httpService.get(this.serviceApi + '/' + id + this.detailsApi);
    }
}
