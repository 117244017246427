/**

 */
import {HttpService} from './http.service';

export class FTBaseService {
    dataModel = {};
    buttonRole: string;
    headerOptions: any = {
        headers: {
            'Content-Type': 'application/json',
        },
    };

    protected serviceApi: string;

    protected baseApi: string;

    constructor(protected httpService: HttpService) {

    }

    getList() {
        return this.httpService.getRequest(this.serviceApi + '/list', this.headerOptions);
    }

    getListWithPage(currentPage: number, pageSize: number) {
        return this.httpService.getRequest(this.serviceApi + `/list/${currentPage}/${pageSize}`, this.headerOptions);
    }

    getByID(id: string) {
        return this.httpService.getRequest(this.serviceApi + '/' + id, this.headerOptions);
    }

    add(data) {
        return this.httpService.postRequest(this.serviceApi + '/', data, this.headerOptions);
    }

    update(data) {
        return this.httpService.putRequest(this.serviceApi + '/update', data, this.headerOptions);
    }

    delete(id: string) {
        return this.httpService.deleteRequest(this.serviceApi + '/' + id, this.headerOptions);
    }

}
