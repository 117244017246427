import {Component, Input, OnInit} from '@angular/core';
import {PopoverRef} from '../../core/popover/popover-ref';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ONLY_NUMBERS} from '../../core/lib/services/custom-validators.service';
import {Observable} from 'rxjs';
import {ResponseModel} from '../../core/lib/model/response.model';
import {UserService} from '../../services/user.service';
import {environment} from '../../../environments/environment';
import {TranslateService} from '@ngx-translate/core';
import {AuthUserModel} from '../../core/auth/_models/auth-user.model';

@Component({
    selector: 'account-overlay',
    templateUrl: './account-overlay.component.html',
    styleUrls: ['./account-overlay.component.scss']
})
export class AccountOverlayComponent implements OnInit {
    accountData: any;
    userProfile$: AuthUserModel;
    form: FormGroup;

    constructor(private overlayRef: PopoverRef, private userService: UserService, private translateService: TranslateService) {
        this.accountData = this.overlayRef.data;
        this.userProfile$ = new AuthUserModel();
        this.userProfile$.sub = this.accountData.sub;
        this.userProfile$.roles = this.accountData[environment.auth0.rulesUrl + '/roles'];
        this.userProfile$.name = this.accountData.name;
        this.userProfile$.email = this.accountData.email;
        this.userProfile$.email_verified = this.accountData.email_verified;
        this.userProfile$.updated_at = this.accountData.updated_at;
        this.userProfile$.sub = this.accountData.sub;
        this.userProfile$.picture = this.accountData.picture;
        this.userProfile$.nickname = this.accountData.nickname;
        this.userProfile$.notifyEmail = this.accountData[environment.auth0.rulesUrl + '/user_metadata'].notify_email;
        this.userProfile$.notifySms = this.accountData[environment.auth0.rulesUrl + '/user_metadata'].notify_sms;
        this.userProfile$.organisationName = this.accountData[environment.auth0.rulesUrl + '/user_metadata'].organisation_name;
        this.userProfile$.userId = this.accountData[environment.auth0.rulesUrl + '/user_metadata'].user_id;
        this.userProfile$.alarmNotificationOption = this.accountData[environment.auth0.rulesUrl + '/user_metadata'].alarm_notification_option;
        this.userProfile$.languagePreference = this.accountData[environment.auth0.rulesUrl + '/user_metadata'].languagePreference;
        this.updateRolesTranslation(this.userProfile$.roles);
    }

    closeOverlay() {
        this.overlayRef.close();
    }

    save() {
        if (this.form.valid) {
            this.overlayRef.close(this.form.value);
        }
    }

    ngOnInit(): void {
        this.initForm();
    }

    initForm() {
        this.form = new FormGroup({
            id: new FormControl(this.userProfile$.userId, {
                updateOn: 'blur',
                validators: [Validators.required]
            }),
            name: new FormControl(this.userProfile$.name, {
                updateOn: 'blur',
                validators: [Validators.required]
            }),
            phoneNumber: new FormControl(this.userProfile$.notifySms, {
                updateOn: 'blur',
                validators: [Validators.required, Validators.pattern(ONLY_NUMBERS)],
            }),
            authId: new FormControl(this.userProfile$.sub, {
                updateOn: 'blur',
            }),
            email: new FormControl(this.userProfile$.email, {
                updateOn: 'blur',
                validators: [Validators.required, Validators.email],
                asyncValidators: [this.checkInUseEmail.bind(this)],
            }),
            alarmNotificationOption: new FormControl(this.userProfile$.alarmNotificationOption, {validators: [Validators.required]}),
            languagePreference: new FormControl(this.userProfile$.languagePreference, {validators: [Validators.required]}),
            password: new FormControl ('', Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}')),
            confirmPassword: new FormControl ('', Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}'))
        }, { validators: [this.checkIfMatchingPasswords('password', 'confirmPassword')]});

    }
    checkInUseEmail(control) {
        const controlValue = control.value;

        return new Observable(observer => {
            if (controlValue != this.userProfile$.email) {
                this.userService.checkEmail(controlValue, this.userProfile$.userId).then((res: ResponseModel) => {
                    if (res.success) {
                        const observerResult = res.result ? { alreadyInUse: true } : null;
                        observer.next(observerResult);
                        observer.complete();
                    }
                });
            } else {
                observer.next(null);
                observer.complete();
            }
        });
    }

    checkIfMatchingPasswords(passwordKey: string, passwordConfirmationKey: string) {
        return (group: FormGroup) => {
            // tslint:disable-next-line:one-variable-per-declaration
            const passwordInput = group.controls[passwordKey],
                passwordConfirmationInput = group.controls[passwordConfirmationKey];
            if (passwordInput.value !== passwordConfirmationInput.value) {
                passwordConfirmationInput.setErrors({passwordMismatch: true});
                return { passwordMismatch: true };
            } else {
                passwordConfirmationInput.setErrors(null);
                return null;
            }
        };
    }

    updateRolesTranslation(roles: any[]) {
        if (roles != undefined) {
            const translatedRoles = [];

            this.userProfile$.roles = this.accountData[environment.auth0.rulesUrl + '/roles'];
            roles.forEach((role: string) => {
                translatedRoles.push(this.translateService.instant('ROLES.' + role.replace(' ','_').toUpperCase()));
            });
            return translatedRoles.join(', ');
        } else {
            return '';
        }
    }
}
