import {HttpService} from '../core/lib/services/http.service';
import {Injectable} from '@angular/core';
import {AlarmResult} from 'src/app/alarmResponse';
import {merge, Observable} from 'rxjs';
import {AlarmDetailsModel} from '../models/alarm-details.model';
import {CommonService} from "./common.service";
import {AuthService} from "../core/auth/_services/auth.service";
import {Building, LocationAreaDetail} from "../AdminApi";

@Injectable()
export class AlarmService {

  protected serviceApi = 'locations';
  public results = [];

  constructor(protected httpService: HttpService,
              public auth: AuthService,
              private common: CommonService,
             ) {

  }

  getLocationAlarms(locationAreaDetails: LocationAreaDetail) {
    // Gather all sensors of all locations accessible to this user
    let sensors = Array<string>();
    const allSensors = Array<string>();
    for (const building of locationAreaDetails.buildings) {
      for (const floor of building.floors) {
        for (const site of floor.sites) {
          sensors = site.sensors.map((sensor) => sensor.devId);
          allSensors.push(...sensors);
        }
      }
    }

    //const allSensors = accessList.map((loc) => loc.access).reduce((a, b) => [...a, ...b]);

    const data = JSON.stringify({ devids: allSensors });
    const time = this.common.unixUTCHoursAgoSnapped(48); //5843
    this.results = [];
    const url = `/alarmsmulti/${time}`;
    // Do 1 request for alarms of all sensors of all locations accessible to this user
    const res = this.httpService.postRequest(url, data, {
      withCredentials: false,
    }) as Observable<Array<AlarmResult>>;
    return res;
  }

  listResult(location: Building, alarmList: AlarmResult[]) {
    if (alarmList.length > 0)  {
      const alarmDetailsModel = new AlarmDetailsModel();
      alarmDetailsModel.location = location;
      const alarmListFiltered = this.filterDuplicateAlarms(alarmList);
      const severity = alarmListFiltered.map((x) => x.severity).reduce((a, b) => a + b);
      const alarmCount = alarmListFiltered.length;
      alarmDetailsModel.alarmCount = alarmCount;

      alarmDetailsModel.alarmList = alarmListFiltered
          .map((alarm) => ({
            ...alarm,
            severityIndicator: this.common.getSeverityIndicator(alarm.severity),
            measurementType: alarm.filters[0].measurement,
            alarmNameTr: this.common.translateAlarmName(alarm.alarmName),
            icon: this.common.getAlarmIcon(alarm.alarmName),
          }))
          .sort((a, b) => {
            if (a.severity > b.severity) {
              return -1;
            }
            if (b.severity > a.severity) {
              return 1;
            }
            return a.receivedTime > b.receivedTime ? -1 : 1;
          });

      alarmDetailsModel.severity = severity;

      return alarmDetailsModel;
    } else {
      const alarmDetailsModel = new AlarmDetailsModel();
      alarmDetailsModel.location = location;
      alarmDetailsModel.alarmCount = 0;
      alarmDetailsModel.alarmList = [];
      alarmDetailsModel.severity = 0;
      return alarmDetailsModel;
    }
  }

  compareByLocation(a: { location: Building }, b: { location: Building }) {
    if (a.location.name.toLowerCase() > b.location.name.toLowerCase()) {
      return 1;
    }
    if (a.location.name.toLowerCase() < b.location.name.toLowerCase()) {
      return -1;
    }
    return 0;
  }

  sortBySeverity(a: { severity: number }, b: { severity: number }) {
    if (a.severity < b.severity) {
      return 1;
    }
    if (a.severity > b.severity) {
      return -1;
    }
    return 0;
  }

  getAlarmIconMapViewClass(index) {
    let iconClass = '';
    switch (index) {
      case 0:
        iconClass = 'alarm-icon-top-right';
        break;
      case 1:
        iconClass = 'alarm-icon-right';
        break;
      case 2:
        iconClass = 'alarm-icon-bottom-right';
        break;
      case 3:
        iconClass = 'alarm-icon-top-left';
        break;
      case 4:
        iconClass = 'alarm-icon-left';
        break;
      case 5:
        iconClass = 'alarm-icon-bottom-left';
        break;
      default:
        iconClass = 'alarm-icon-top-right';
    }
    return iconClass;
  }

  private filterDuplicateAlarms(alarmList: AlarmResult[]) {
    const uniqueAlarmLists = [];
    for (const alarm of alarmList) {
      if (uniqueAlarmLists.length > 0) {
        let addAlarm = true;
        for (let i = 0 ; i < uniqueAlarmLists.length; i++) {
          if (uniqueAlarmLists[i].filters[0].measurement.toLowerCase() === alarm.filters[0].measurement.toLowerCase() &&
              uniqueAlarmLists[i].devId.toUpperCase() === alarm.devId.toUpperCase()) {
            addAlarm = false;
            if (alarm.receivedTime >  uniqueAlarmLists[i].receivedTime) {
              uniqueAlarmLists[i] = alarm;
            }
          }
        }
        if (addAlarm) {
          uniqueAlarmLists.push(alarm);
        }
      } else {
        uniqueAlarmLists.push(alarm);
      }
    }
    return uniqueAlarmLists;
  }

  getAlarmList(result: Array<AlarmResult>, locationAreaDetails: LocationAreaDetail): AlarmDetailsModel[] {
    let alarmList: AlarmDetailsModel[] = [];
    const nonDismissed = result.filter((x) => (x.dismissed ? !x.dismissed : true));
    for (const location of locationAreaDetails.buildings) {
      // Figure out under which location(s) each alarm should be displayed
      const allSensors = this.common.getBuildingSensors(location);
      let locationAlarmResults = [];
      if (allSensors.length > 0) {
        locationAlarmResults = allSensors
            .map((sensor) => nonDismissed.filter((item) => item.devId === sensor))
            .reduce((a, b) => [...a, ...b]);
      }
      const alarmListModel = this.listResult(location, locationAlarmResults);
      alarmListModel.alarmList = alarmListModel.alarmList.sort(this.sortBySeverity);
      alarmList.push(alarmListModel);
    }
    alarmList = alarmList.sort(this.compareByLocation);
    return alarmList;
  }
}
