<div class="tracker-overlay-container">
    <img (click)="closeOverlay()" class="close-overlay-img" src="assets/images/cancel_dark.svg"/>

    <div class="container-body">
        <div class="hmap-container">
            <div class="hmap-frame">
                <div id="hmap"></div>
            </div>
        </div>
    </div>
</div>
