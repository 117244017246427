<div class="notifications-overlay-container">
    <img (click)="closeOverlay()" class="close-overlay-img" src="assets/images/cancel.svg"/>
    <div class="container-header">
        <div class="row first-row">
            <div class="personItem">
                <div class="account-list-logo-container">
                    <img class="profile-img" [src]="'/assets/images/notifications.svg'" alt="measurement type icon" />
                </div>
                <div class="personDetail person-list-title-container">
                    <p class="profileName">
                        <a>{{ "ACCOUNT.NOTIFICATION_MANAGEMENT_PAGE" | translate }}</a>
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="container-body" [formGroup]="form">
        <div class="row form-content">
            <div class="email-radio" >
                <label style="width: 100%;">{{'USER_ALARM_NOTIFICATION.LABEL_EMAIL' | translate }}</label>
                <mat-radio-group
                        (change)="emailAlertNotificationChanged()"
                        formControlName="emailAlertNotification"
                >
                    <mat-radio-button style="width: 100%; padding-bottom: 0.5vw;" [value]="1">
                        {{'USER_ALARM_NOTIFICATION.YES' | translate }}
                    </mat-radio-button>
                    <mat-radio-button style="width: 100%" [value]="0">
                        {{'USER_ALARM_NOTIFICATION.NO' | translate }}
                    </mat-radio-button>
                </mat-radio-group>
                <mat-error *ngIf="form.controls['emailAlertNotification'] && form.controls['emailAlertNotification'].hasError('required')">  {{  'USER_ALARM_NOTIFICATION.FIELD_REQUIRED' | translate}}</mat-error>
            </div>
            <div class="email-radio">
                <label style="width: 100%;">{{'USER_ALARM_NOTIFICATION.LABEL_PHONE' | translate }}</label>
                <mat-radio-group
                        (change)="smsAlertNotificationChanged()"
                        formControlName="smsAlertNotification"
                >
                    <mat-radio-button style="width: 100%; padding-bottom: 0.5vw;" [value]="1">
                        {{'USER_ALARM_NOTIFICATION.YES' | translate }}
                    </mat-radio-button>
                    <mat-radio-button style="width: 100%" [value]="0">
                        {{'USER_ALARM_NOTIFICATION.NO' | translate }}
                    </mat-radio-button>
                </mat-radio-group>
                <mat-error *ngIf="form.controls['smsAlertNotification'].hasError('required')">  {{  'USER_ALARM_NOTIFICATION.FIELD_REQUIRED' | translate}}</mat-error>
            </div>
            <div formArrayName="alertSchedule" class="table-container">
                <div *ngIf="form.controls['emailAlertNotification'].value == 1" class="alarms-sett">
                    <p>{{ 'USER_ALARM_NOTIFICATION.EMAIL_ALARM_SCHEDULE' | translate}}</p>
                    <table width="30" class="mat-table" [formGroupName]="0">
                        <tr class="mat-header-row">
                            <th class="mat-header-cell">{{ 'USER_ALARM_NOTIFICATION.MONDAY' | translate}}</th>
                            <th class="mat-header-cell">{{ 'USER_ALARM_NOTIFICATION.TUESDAY' | translate}}</th>
                            <th class="mat-header-cell">{{ 'USER_ALARM_NOTIFICATION.WEDNESDAY' | translate}}</th>
                            <th class="mat-header-cell">{{ 'USER_ALARM_NOTIFICATION.THURSDAY' | translate}}</th>
                            <th class="mat-header-cell">{{ 'USER_ALARM_NOTIFICATION.FRIDAY' | translate}}</th>
                            <th class="mat-header-cell">{{ 'USER_ALARM_NOTIFICATION.SATURDAY' | translate}}</th>
                            <th class="mat-header-cell">{{ 'USER_ALARM_NOTIFICATION.SUNDAY' | translate}}</th>
                            <th class="mat-header-cell">{{ 'USER_ALARM_NOTIFICATION.FROM' | translate}}</th>
                            <th class="mat-header-cell">{{ 'USER_ALARM_NOTIFICATION.TO' | translate}}</th>
                        </tr>
                        <tr class="mat-row">
                            <td class="mat-cell">
                                <mat-checkbox  formControlName="mon">
                                </mat-checkbox>
                            </td>
                            <td class="mat-cell">
                                <mat-checkbox  formControlName="tue">
                                </mat-checkbox>
                            </td>
                            <td class="mat-cell">
                                <mat-checkbox  formControlName="wed">
                                </mat-checkbox>
                            </td>
                            <td class="mat-cell">
                                <mat-checkbox  formControlName="thur">
                                </mat-checkbox>
                            </td>
                            <td class="mat-cell">
                                <mat-checkbox  formControlName="fri">
                                </mat-checkbox>
                            </td>
                            <td class="mat-cell">
                                <mat-checkbox  formControlName="sat">
                                </mat-checkbox>
                            </td>
                            <td class="mat-cell">
                                <mat-checkbox  formControlName="sun">
                                </mat-checkbox>
                            </td>
                            <td class="mat-cell">
                                <input [format]="24" [ngxTimepicker]="emailFromPicker" formControlName="from">
                                <mat-error class="time-errors" *ngIf="this.getSchedules().controls[0].get('from').hasError('required')">
                                      {{  'USER_ALARM_NOTIFICATION.FIELD_REQUIRED' | translate}}
                                </mat-error>
                                <ngx-material-timepicker #emailFromPicker></ngx-material-timepicker></td>
                            <td class="mat-cell">
                                <input [format]="24" [ngxTimepicker]="emailToPicker" formControlName="to">
                                <mat-error class="time-errors" *ngIf="this.getSchedules().controls[0].get('to').hasError('required')">
                                      {{  'USER_ALARM_NOTIFICATION.FIELD_REQUIRED' | translate}}
                                </mat-error>
                                <mat-error
                                        class="time-errors"
                                        *ngIf="this.getSchedules().controls[0].hasError('timingMismatch')">
                                    {{  'USER_ALARM_NOTIFICATION.TO_LARGER_THAN_FROM' | translate}}
                                </mat-error>
                                <ngx-material-timepicker #emailToPicker></ngx-material-timepicker></td>
                            <mat-error *ngIf="form.hasError('required')">  {{  'USER_ALARM_NOTIFICATION.FIELD_REQUIRED' | translate}}</mat-error>
                        </tr>
                    </table>
                    <div class="night-shift-info" *ngIf="(this.getSchedules().controls[0].get('from').value != '' && this.getSchedules().controls[0].get('to').value != '')  && (this.getSchedules().controls[0].get('from').value > this.getSchedules().controls[0].get('to').value)">
                        {{ 'USER_ALARM_NOTIFICATION.NIGHT_SHIFT' | translate }}
                    </div>
                </div>
                <div *ngIf="form.controls['smsAlertNotification'].value == 1" class="alarms-sett">
                    <p>{{ 'USER_ALARM_NOTIFICATION.SMS_ALARM_SCHEDULE' | translate}}</p>
                    <table class="mat-table" [formGroupName]="1">
                        <tr class="mat-header-row">
                            <th class="mat-header-cell">{{ 'USER_ALARM_NOTIFICATION.MONDAY' | translate}}</th>
                            <th class="mat-header-cell">{{ 'USER_ALARM_NOTIFICATION.TUESDAY' | translate}}</th>
                            <th class="mat-header-cell">{{ 'USER_ALARM_NOTIFICATION.WEDNESDAY' | translate}}</th>
                            <th class="mat-header-cell">{{ 'USER_ALARM_NOTIFICATION.THURSDAY' | translate}}</th>
                            <th class="mat-header-cell">{{ 'USER_ALARM_NOTIFICATION.FRIDAY' | translate}}</th>
                            <th class="mat-header-cell">{{ 'USER_ALARM_NOTIFICATION.SATURDAY' | translate}}</th>
                            <th class="mat-header-cell">{{ 'USER_ALARM_NOTIFICATION.SUNDAY' | translate}}</th>
                            <th class="mat-header-cell">{{ 'USER_ALARM_NOTIFICATION.FROM' | translate}}</th>
                            <th class="mat-header-cell">{{ 'USER_ALARM_NOTIFICATION.TO' | translate}}</th>
                        </tr>
                        <tr class="mat-row">
                            <td class="mat-cell">
                                <mat-checkbox  formControlName="mon">
                                </mat-checkbox>
                            </td>
                            <td class="mat-cell">
                                <mat-checkbox  formControlName="tue">
                                </mat-checkbox>
                            </td>
                            <td class="mat-cell">
                                <mat-checkbox  formControlName="wed">
                                </mat-checkbox>
                            </td>
                            <td class="mat-cell">
                                <mat-checkbox  formControlName="thur">
                                </mat-checkbox>
                            </td>
                            <td class="mat-cell">
                                <mat-checkbox  formControlName="fri">
                                </mat-checkbox>
                            </td>

                            <td class="mat-cell">
                                <mat-checkbox  formControlName="sat">
                                </mat-checkbox>
                            </td>
                            <td class="mat-cell">
                                <mat-checkbox  formControlName="sun">
                                </mat-checkbox>
                            </td>
                            <td class="mat-cell">
                                <input [format]="24" [ngxTimepicker]="smsFromPicker" formControlName="from">
                                <mat-error class="time-errors" *ngIf="this.getSchedules().controls[1].get('from').hasError('required')">
                                      {{  'USER_ALARM_NOTIFICATION.FIELD_REQUIRED' | translate}}
                                </mat-error>
                                <ngx-material-timepicker  [ngxMaterialTimepickerTheme]="{container: {buttonColor: '#000000'}}" #smsFromPicker></ngx-material-timepicker></td>
                            <td class="mat-cell">
                                <input [format]="24" [ngxTimepicker]="smsToPicker" formControlName="to">
                                <mat-error class="time-errors" *ngIf="this.getSchedules().controls[1].get('to').hasError('required')">
                                      {{  'USER_ALARM_NOTIFICATION.FIELD_REQUIRED' | translate}}
                                </mat-error>
                                <mat-error
                                        class="time-errors"
                                        *ngIf="this.getSchedules().controls[1].hasError('timingMismatch')">
                                    {{  'USER_ALARM_NOTIFICATION.TO_LARGER_THAN_FROM' | translate}}
                                </mat-error>
                                <ngx-material-timepicker #smsToPicker></ngx-material-timepicker></td>
                        </tr>
                    </table>
                    <div class="night-shift-info" *ngIf="(this.getSchedules().controls[1].get('from').value != '' && this.getSchedules().controls[1].get('to').value != '')  && (this.getSchedules().controls[1].get('from').value > this.getSchedules().controls[1].get('to').value)">
                        {{ 'USER_ALARM_NOTIFICATION.NIGHT_SHIFT' | translate }}
                    </div>
                </div>
            </div>
        </div>
        <div class="row person-button-row action-buttons">
            <button mat-raised-button class="save-button" (click)="save()">{{ "GENERAL.SAVE" | translate}}</button>
            <button mat-raised-button class="cancel-button" (click)="closeOverlay()">{{ "GENERAL.CANCEL" | translate}}</button>
        </div>
    </div>

</div>
