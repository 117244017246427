
export class AuthUserModel {
    nickname: string;
    roles: any[];
    notifyEmail: string;
    notifySms: string;
    organisationName: string;
    userId: number;
    name: string;
    picture: string;
    email: string;
    email_verified: boolean;
    sub: string;
    updated_at: string;
    alarmNotificationOption: number;
    languagePreference: string;
}
