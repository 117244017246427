<div class="card-container">
  <ng-template #itemTemplate>

  </ng-template>

  <div class="card-frame">
    <div id="card" [ngStyle]="{ 'height.vh': 100 }">
      <mat-grid-list  cols="7" rowHeight="1:1">
         <ng-container *ngFor="let item of alarmList;">
           <ng-container *ngIf="item.alarmList.length == 0; else alarmsTemplate">
             <mat-grid-tile (click)="onBuildingPopupClick($event, item.location.id)">
               <ng-container *ngIf="item.alarmList.length == 0; else alarmsTemplate">
                 <mat-grid-tile-header>
                   <div class="subtext-container">
                     <p class="grid-tile-subtext">{{ item.location.name }}</p>
                   </div>
                 </mat-grid-tile-header>
                 <mat-grid-tile-footer>
                   <div class="subtext-container">
                     <p class="grid-tile-subtext customers">{{ getCustomerNames(item.location) }}</p>
                   </div>
                 </mat-grid-tile-footer>
               </ng-container>
             </mat-grid-tile>
           </ng-container>
           <ng-template #alarmsTemplate>
             <mat-grid-tile #liItm [attr.data-id]="item.location.id" (click)="onAlarmPopupClicked(liItm)" class="mat-grid-tile alarm-level-{{item.alarmList[0].severity}}">
               <mat-grid-tile-header>
                 <div class="subtext-container">
                   <p class="grid-tile-subtext-icons">{{item.location.name}}</p>
                 </div>
               </mat-grid-tile-header>
              <mat-grid-tile-footer>
                <div class="card-icon-container">
                  <ng-container *ngFor="let alarmItem of item.alarmList;">
                    <img class="grid-tile-alarm-icon" [src]="'assets/images/icons/alarms/lvl-'+ alarmItem.severity + '/' + alarmItem.icon + '.svg'"/>
                  </ng-container>
                </div>
              </mat-grid-tile-footer>
             </mat-grid-tile>
           </ng-template>
         </ng-container>
      </mat-grid-list>
    </div>
  </div>
</div>
