import {Component, OnInit, AfterViewInit} from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { CommonService } from 'src/app/services/common.service';
import { LocalDataService } from 'src/app/services/local-data.service';
import {AlarmService} from '../../services/alarm.service';
import {AlarmDetailsModel} from '../../models/alarm-details.model';
import {Popover} from '../../core/popover/popover.service';
import {AuthService} from 'src/app/core/auth/_services/auth.service';
import {LocalDataConstants} from '../../core/lib/utility/local-data.constants';
import {LocationAreaNameModel} from '../../models/location-area-name.model';
import {LocationAreaDetail, LocationAreaDetailResponse} from 'src/app/AdminApi';
import {LocationService} from '../../services/location.service';
import {NavigationEnd, Router} from '@angular/router';
import {CareFacilityDetailOverlayComponent} from '../../overlays/care-facility-detail/care-facility-detail-overlay.component';
import {ResponseModel} from '../../core/lib/model/response.model';
import {AlarmListService} from '../../services/alarm-list.service';

@Component({
  selector: 'app-alarms-list',
  templateUrl: './alarms-list.component.html',
  styleUrls: ['./alarms-list.component.scss'],
})
export class AlarmsListComponent implements OnInit, AfterViewInit {
  targetHeight = 800;
  locationAreaList: LocationAreaNameModel[];
  locationAreaDetails: LocationAreaDetail;
  alarmList: AlarmDetailsModel[] = [];
  selectedLocationArea: number;
  timer: any;
  hasAlarms = false;
  alarmListLoading = true;
  REFRESH_RATE = 30 * 1000; // 30 sec refresh rate

  constructor(
    private localData: LocalDataService,
    private locationService: LocationService,
    public auth: AuthService,
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    private common: CommonService,
    private alarmService: AlarmService,
    private alarmListService: AlarmListService,
    private router: Router,
    private popper: Popover
  ) {}

  toggleCheckbox(listItem) {
    listItem.checkboxToggle = true;
  }

  listItemChecked(item) {
    console.log(item);
  }

  ngAfterViewInit(): void {
    this.router.events.subscribe(this.routeChange.bind(this));
  }

  private routeChange(val: any) {
    if (val instanceof NavigationEnd) {
      this.processLocationAlarms();
    }
  }

  async ngOnInit() {

    this.targetHeight = (window.innerHeight / 10) - ((window.innerHeight / 10) / 5);
    await this.delay(1000);
    this.processLocationAlarms();
    this.alarmListService.currentMessage.subscribe(data => {
      this.alarmList = data;
      this.hasAlarms = false;
      this.alarmList.forEach((alarm) => {
        if (alarm.alarmCount > 0) {
          this.hasAlarms = true;
        }
      });
    }
    );
  }

  delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
  }

  processLocationAlarms() {
    this.locationAreaList = this.localData.get(LocalDataConstants.LOCATION_NAMES);
    if ((this.locationAreaList === undefined || this.locationAreaList.length == 0)
        || (this.locationAreaDetails === undefined || this.locationAreaDetails === null)
    )  {
      this.initLocationList();
    } else {
      this.locationAreaList = this.locationAreaList.sort((a,b) => {
        return b.name.localeCompare(a.name);
      }).reverse();
      this.initLocationDetails();
    }
  }

  onAlarmPopupClicked(origin, alarmDetailsModel) {
    this.toggleCheckbox(alarmDetailsModel);
    let b = this.locationAreaDetails.buildings.filter((x) => (x.id == alarmDetailsModel.location.id));

    const ref = this.popper.open<{  }>({
      content: CareFacilityDetailOverlayComponent,
      origin,
      data: {
        building: b[0],
        alarm: alarmDetailsModel,
        locationAreaDetails: this.locationAreaDetails,
        locationId: alarmDetailsModel.location.id
      }
    });

    ref.afterClosed$.subscribe(res => {
      this.processLocationAlarms();
      alarmDetailsModel.checkboxToggle = false;
    });

  }

  private initLocationDetails(): void {
    this.locationAreaList = this.localData.get(LocalDataConstants.LOCATION_NAMES);
    this.locationAreaList = this.locationAreaList.sort((a,b) => {
      return b.name.localeCompare(a.name);
    }).reverse();
    this.selectedLocationArea = this.localData.get(LocalDataConstants.SELECTED_LOCATION);
    this.locationService.getLocationAreaDetails(
        this.locationAreaList[this.selectedLocationArea].id
    ).then((result: LocationAreaDetailResponse) => {
      this.locationAreaDetails = result.result;
      this.getLocationAlarms();
    });
  }

  private getLocationAlarms() {
   /* this.alarmListLoading = true;*/
    console.log('refreshing at: ' + new Date());
    if (this.timer) {
      clearTimeout(this.timer);
    }
    this.timer = setTimeout(this.initLocationDetails.bind(this), this.REFRESH_RATE);

    this.alarmList = [];
    this.alarmService.getLocationAlarms(this.locationAreaDetails).subscribe(
        // tslint:disable-next-line:no-shadowed-variable
        (result) => {
          this.alarmList = this.alarmService.getAlarmList(result, this.locationAreaDetails);
          this.alarmListLoading = false;
          this.localData.set(LocalDataConstants.ALARM_LIST, this.alarmList);
          this.alarmListService.changeMessage(this.alarmList);
          this.hasAlarms = false;
          this.alarmList.forEach((alarm) => {
            if (alarm.alarmCount > 0) {
              this.hasAlarms = true;
            }
          });
        }
    );

  }

  initLocationList() {
    this.locationService.getLocationAreaNames().then((res: ResponseModel) => {
      if (res.success) {
        const locations = res.result.sort((a, b) => {
          return ('' + b.name).localeCompare(a.name);
        });
        this.localData.set(LocalDataConstants.LOCATION_NAMES, locations);
        if (res.result.length > 0) {
          this.initLocationDetails();
        } else {
          this.alarmListLoading = false;
        }
      } else {
        console.log(res.message);
      }
    });
  }
}
