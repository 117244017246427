import {AlarmListModel} from './alarm-list.model';
import {BaseFtModel} from '../core/lib/model/base-ft.model';
import {Building} from "../AdminApi";

/**
 * Created by Edison Rama
 */

export class AlarmDetailsModel extends BaseFtModel {
  alarmCount: number;
  alarmList: AlarmListModel[];
  //location: Location;
  location: Building;
  severity: number;
  checkboxToggle: boolean;
}
