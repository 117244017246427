// Angular
import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
// RxJS
import { Observable } from 'rxjs';
import {mergeMap, tap} from 'rxjs/operators';
import {AuthService} from "../../auth/_services/auth.service";

/**
 * More information there => https://medium.com/@MetonymyQT/angular-http-interceptors-what-are-they-and-how-to-use-them-52e060321088
 */
@Injectable()
export class InterceptService implements HttpInterceptor {

  constructor(private authService: AuthService) {

  }
  // intercept request and add token
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    if (request.url.indexOf('/assets/i18n/') > -1) {
        return next.handle(request);
    }
    return this.authService.getTokenSilently$().pipe(mergeMap((token) => {
      // tslint:disable-next-line:no-debugger
      // modify request
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`
        }
      });


      if (!request.headers.has('Content-Type')) {
        request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
      }
      request = request.clone({ headers: request.headers.set('Accept', 'application/json') });

      return next.handle(request).pipe(
        tap(
          event => {
            if (event instanceof HttpResponse) {
               if (event.body.responseCode !== 200) {
                console.log(event.body.message);
               }
              // http response status code
            }
          },
          error => {
            // http response status code
            // console.log('----response----');
            // console.error('status code:');
            // tslint:disable-next-line:no-debugger
            console.error(error.status);
            console.error(error.message);
            // console.log('--- end of response---');
          }
        )
      );
    }));
  }
}
