// Angular
import {Component, HostBinding, OnInit, Input, OnChanges} from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
// RxJS
import { filter } from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {UserService} from "../../services/user.service";
import {ResponseModel} from "../../core/lib/model/response.model";
import {OrganisationTypeModel} from "../../models/organisation-type.model";
import {OrganisationTypesEnum} from "../../core/lib/enums/organisation-types.enum";
import {NgxPermissionsService} from "ngx-permissions";
// Translate

class LanguageFlag {
  lang: string;
  name: string;
  flag: string;
  active?: boolean;
}

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss']
})
export class LanguageSelectorComponent implements OnInit, OnChanges {
  // Public properties
  @Input() iconType: '' | 'brand';

  @Input('languages') languages: LanguageFlag[];

  language: LanguageFlag = new LanguageFlag();
  languageBtnActive = false;
  selectedLanguage = '';
  organisationTypeModel: OrganisationTypeModel;
  selectedLanguageFlag = '';
  selectedLanguageIndex = -1;
  selectedLanguageName = '';
 /* languages: LanguageFlag[] = [];*/
  notSelectedLanguage: LanguageFlag = new LanguageFlag();

  /**
   * Component constructor
   *
   * @param translationService: TranslationService
   * @param router: Router
   */
  constructor(private translationService: TranslateService, private router: Router) {
  }


  /**
   * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
   */

  /**
   * On init
   */
  ngOnInit() {
    this.router.events
      .pipe(filter(event => event instanceof NavigationStart))
      .subscribe(event => {
        this.setSelectedLanguage();
      });
  }

  // Runs whenever component @Inputs change
  ngOnChanges () {
    if (this.languages) {
      this.initLanguages();
    }
  }

  /**
   * Set language
   *
   * @param lang: any
   */
  setLanguage(lang) {
    this.translationService.use(lang);
    let index = 0;
    if (this.languages != undefined) {
      this.languages.forEach((language: LanguageFlag) => {
        if (language.lang === lang) {
          language.active = true;
          this.selectedLanguageIndex = index;
          this.selectedLanguage = language.lang;
          this.selectedLanguageFlag = language.flag;
          this.translationService.get('LANGUAGES.' + language.lang.toUpperCase()).subscribe((result) => {
            this.selectedLanguageName = result;
            this.language = language;
          });

        } else {
          language.active = false;
          this.notSelectedLanguage = language;
        }
        index++;
      });
    }
  }

  /**
   * Set selected language
   */
  setSelectedLanguage(): any {
    console.log("Language changed to: " + this.translationService.currentLang);
    this.setLanguage(this.translationService.currentLang);
  }

  toggleLanguageSelection() {
    this.languageBtnActive = !this.languageBtnActive;
  }

  languageChanged(event) {
    this.selectedLanguage = event.getAttribute('data-id');
    this.setLanguage(this.selectedLanguage);
    this.refreshLanguageNames();
    this.toggleLanguageSelection();
  }


  private refreshLanguageNames() {
    this.languages.forEach((language: LanguageFlag) => {
      this.translationService.get('LANGUAGES.' + language.lang.toUpperCase()).subscribe((result) => {
        language.name = result;
      });
    });
  }

  private initLanguages() {
    let englishName = '';
    let finnishName = '';
    this.translationService.get('LANGUAGES.EN').subscribe((res) => {
      englishName = res;
      this.translationService.get('LANGUAGES.FI').subscribe((resFinnish) => {
        finnishName = resFinnish;
        this.setSelectedLanguage();
      });
    });

  }
}
