import { Inject, Injectable } from '@angular/core';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';

@Injectable({
  providedIn: 'root',
})
export class LocalDataService {
  constructor(@Inject(LOCAL_STORAGE) private storage: StorageService) {}

  public set(key: string, value: any): void {
    return this.storage.set(key, value);
  }

  public get(key: string): any {
    return this.storage.get(key);
  }

  public has(key: string): boolean {
    return this.storage.has(key);
  }

  public remove(key: string): void {
    return this.storage.remove(key);
  }

  public clear(): void {
    return this.storage.clear();
  }
}
