// Angular
import {
  Component,
  OnInit,
  Inject,
  ChangeDetectionStrategy, Renderer2, ElementRef, QueryList, ViewChildren, AfterViewInit,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {TranslateService} from "@ngx-translate/core";
import {GraphHelper} from "src/app/helpers/graph.helper";
import {CommonService} from "src/app/services/common.service";
import {HttpService} from "../../core/lib/services/http.service";
import {Building} from "src/app/AdminApi";
import {Chart, ChartOptions} from "chart.js";
import {Label} from "ng2-charts";
import {ChartType} from "chart.js";
import * as pluginAnnotation from "chartjs-plugin-annotation";

export interface DialogData {
  sensorGraphs: [];
  building: Building;
  selectedFloor: number;
  selectedSite: number;
  customers: any;
  sensor: string;
  key: string;
  graphHelper: GraphHelper
}

@Component({
	selector: 'app-single-graph-dialog',
	templateUrl: './single-graph.dialog.component.html',
	changeDetection: ChangeDetectionStrategy.Default,
    styleUrls: ['./single-graph.dialog.component.scss']
})
export class SingleGraphDialogComponent implements OnInit {

    public barChartPlugins = [pluginAnnotation];
    sensorGraphs = [];
    form: FormGroup;
    building: Building;
    selectedFloor: number;
    selectedSite: number;
    sensor: string;
    key: string;
    sensorTitle: string = '';
    graphHelper: GraphHelper;
    accessibleSensors: string[] = [];
    meanVal: string = '';
    maxVal: string = '';
    minVal: string = '';
    loading: boolean = true;
    noResults: boolean = false;
    dateSelectionError: boolean = false;
    dateSelectionErrorText: string = '';
    options: ChartOptions;
    datasets = [];
    chartLegend = false;
    chartPlugins = [];
    labels: Label[] = [];
    chartType: ChartType = 'line';
    sensorData  = [];
    customers: any;
    private title: string = '';

    constructor(private fb: FormBuilder,
                public dialogRef: MatDialogRef<SingleGraphDialogComponent>,
                private translateService: TranslateService,
                private common: CommonService,
                private renderer: Renderer2,
                private http: HttpService,
                @Inject(MAT_DIALOG_DATA) public data: DialogData,
    ) {
      this.sensorGraphs = this.data.sensorGraphs;
      this.building = this.data.building;
      this.selectedFloor = this.data.selectedFloor;
      this.selectedSite = this.data.selectedSite;
      this.customers = this.data.customers;
      this.sensor = this.data.sensor;
      this.key = this.data.key;
      this.graphHelper = this.data.graphHelper;
      this.graphHelper.filteringK = this.key;
      this.graphHelper.filteringSensor = this.sensor;
    }

  ngOnInit() {
      if (this.graphHelper.isHenkausDevice(this.key, null, true)) {
        this.changeHours(0.15);
      } else {
        this.changeHours(48);
      }

      this.initDateFilters();
      Chart.pluginService.register(pluginAnnotation);
	}

    private initDateFilters() {
      this.form = this.fb.group({
        fromDate: ['', Validators.required],
        toDate: ['', Validators.required],
        selectHoursFilter: [this.graphHelper.isHenkausDevice(this.key, null, true) ? '0.15' : '48']
      });
    }



  changeHours(hours: number) {
      this.graphHelper.HOURS = hours;
      this.updateDashboard();
    }

    updateDashboard() {
      this.graphHelper.checkGraphHours();

      this.graphHelper.langValue = this.translateService.currentLang;
      this.showDashboard(this.graphHelper.HOURS);
    }


    close() {
      this.dialogRef.close();
    }

  private attachGraphCanvas(k, sensor, sensorData, tempKey, measurementName,installationPlace, fromTime: number, toTime: number) {
    this.loading = false;
    this.noResults = false;
    this.options = this.graphHelper.getGraphOptions(sensorData[k], sensor, tempKey, false, this.translateService.currentLang);
    if (fromTime != null && toTime == null) {
      fromTime = this.common.unixUTCSnappedSince(this.graphHelper.HOURS);
      const toDateObj = new Date();
      toTime = toDateObj.getTime();
    }
    const fromDateLabel = new Date(fromTime).toLocaleDateString('fi-FI');
    const toDateLabel = new Date(toTime).toLocaleDateString('fi-FI');
    const fromTimeLabel = new Date(fromTime).toLocaleTimeString('fi-FI');
    const toTimeLabel = new Date(toTime).toLocaleTimeString('fi-FI');
    let customers = '';
    this.customers.forEach((customer, index) => customers += (index == 0 ? customer.name : ',' + customer.name));
    this.title = measurementName + " - " +
        installationPlace +
        " | "
        + fromDateLabel + " " + fromTimeLabel
        + " - "
        + toDateLabel + " " + toTimeLabel +
        " | " +
        customers;
    this.options.title = {
      text: this.title,
      display: true,
      fontSize: 22
    };
    if (k == 'durationOnScale') {
      var minValue = 'N/A';
      var maxValue = 'N/A';
      var meanValue = 'N/A';

      var minDuration =this.graphHelper.minValue.toFixed(1);
      var minDurationSplit = minDuration.split('.');
      if (minDurationSplit.length > 1) {
        minValue = minDurationSplit[0] + ' min ' + parseFloat('0.'+minDurationSplit[1])  * 60 + ' s';
      } else {
        minValue =  minDurationSplit[0] + ' min';
      }

      var maxDuration =this.graphHelper.maxValue.toFixed(1);
      var maxDurationSplit = maxDuration.split('.');
      if (maxDurationSplit.length > 1) {
        maxValue = maxDurationSplit[0] + ' min ' + parseFloat('0.'+maxDurationSplit[1])  * 60 + ' s';
      } else {
        maxValue =  maxDurationSplit[0] + ' min';
      }

      var meanDuration = this.graphHelper.meanValue.toFixed(1);
      var meanDurationSplit = meanDuration.split('.');
      if (meanDurationSplit.length > 1) {
        meanValue = meanDurationSplit[0] + ' min ' + parseFloat('0.'+meanDurationSplit[1])  * 60 + ' s';
      } else {
        meanValue =  meanDurationSplit[0] + ' min';
      }



      this.minVal = minValue;
      this.maxVal = maxValue;
      this.meanVal = meanValue;

    } else {
      this.minVal = measurementName + ': ' + this.graphHelper.minValue;
      this.maxVal = measurementName + ': ' + this.graphHelper.maxValue;
      this.meanVal = measurementName + ': ' + this.graphHelper.meanValue;
    }


    this.datasets = [this.graphHelper.getDataSpec(tempKey, this.graphHelper.values, measurementName)];
    this.labels = this.graphHelper.labels;

  }

  private initGraph(fromTime: number, toTime: number) {
    const sensorData = this.graphHelper.dataItems[this.sensor];
    const k = this.key;
    this.graphHelper.labels = [];
    this.graphHelper.values = [];
    if (sensorData && sensorData.hasOwnProperty(k)) {
      let tempKey = k === 'temp' ? 'temperature' : k;
      tempKey = tempKey.toUpperCase() === 'MOISTURE' ? 'humidity' : (tempKey.toUpperCase() === 'PERSONWEIGHT') ? 'weightAvg' : tempKey;

      this.graphHelper.labels = [];
      this.graphHelper.values = [];

      const measurementName = this.common.translateMeasurementName(k);
      const installationPlace = this.graphHelper.sensorComments.filter((place) => place.id == this.sensor)[0];
      this.sensorTitle = measurementName + ' - ' + installationPlace.comment;
      this.loading = true;
      this.noResults = false;
      this.attachGraphCanvas(k, this.sensor, sensorData, tempKey, measurementName, installationPlace.comment, fromTime, toTime);

    } else {
      this.loading = false;
      this.noResults = true;
    }
  }

    showWholeDashboard(sensor: string, fromTime: number, toTime: number): void {
      this.sensorData = this.graphHelper.dataItems[sensor];
      const keys = Object.keys(this.sensorData).sort();
      if (!this.sensorGraphs[sensor]) {
        this.sensorGraphs[sensor] = this.graphHelper.filterKeys(this.sensorData, keys, sensor, null);
      }

      this.initGraph(fromTime, toTime);
    }


    listResult2(sensor: string, data: any, minTime: number, fromTime: number, toTime: number): void {
      if (data && data.length > 0) {
        if (this.graphHelper.processDataItems(data, minTime, sensor)) {
          this.showWholeDashboard(sensor, fromTime, toTime);
        }
      } else {
        this.noResults = true;
        this.minVal = 'N/A';
        this.meanVal = 'N/A';
        this.maxVal = 'N/A';
        this.loading = false;
      }
    }

    async showDashboard(fromTime = null, toTime = null): Promise<void> {
      // tslint:disable-next-line:triple-equals
      const floor = this.building.floors.filter((x) => (x.id == this.selectedFloor))[0];

      // tslint:disable-next-line:triple-equals
      const site = floor.sites.filter((x) => (x.id == this.selectedSite))[0];
      this.accessibleSensors = [this.sensor];
      this.graphHelper.locationName = site.name;
      site.sensors =  site.sensors.sort((a,b) => {
        return b.comments.localeCompare(a.comments);
      }).reverse();
      this.graphHelper.sensorComments = [];
      for (const sensor of site.sensors) {
        const comment = { id: sensor.devId, comment: sensor.comments };
        this.graphHelper.sensorComments.push(comment);
      }

      if (fromTime && toTime) {
        const listPrefix = `/archive/${site.id}/${this.sensor}/from/${fromTime}/to/${toTime}`;
        this.http.postRequest(listPrefix, {}, null).subscribe((result) => {
          this.listResult2(this.sensor, result, fromTime, fromTime, toTime);
        }, this.graphHelper.showError.bind(this));
      } else {
        const listPrefix = `/archive/${site.id}/${this.sensor}/from/`;
        const time = this.common.unixUTCSnappedSince(fromTime);
        this.http.postRequest(listPrefix + time, {}, null).subscribe((result) => {
          this.listResult2(this.sensor, result, time, fromTime, toTime);
        }, this.graphHelper.showError.bind(this));
      }
    }

  filterByDate(k: any, sensor: string) {
    const fromDate = this.form.controls.fromDate.value;
    const toDate = this.form.controls.toDate.value;
    if ((fromDate == undefined || fromDate == '') || (toDate == undefined || toDate == '')) {
      var datesToBeSelected = this.translateService.instant('CARE_FACILITY.DATES_NEED_TO_BE_SELECTED');
      this.dateSelectionErrorText = datesToBeSelected;
      this.dateSelectionError = true;
      return false;
    } else {
      this.dateSelectionError = false;
    }
    const toDateObj = new Date(toDate);
    toDateObj.setHours(23, 59, 59, 59);
    const fromDateObj = new Date(fromDate);
    fromDateObj.setHours(0, 0, 0,0);
    const fromDateTime = fromDateObj.getTime();
    const toDateTime = toDateObj.getTime();

    if (fromDateTime > toDateTime) {
      var dateError = this.translateService.instant('CARE_FACILITY.FROM_LARGER_THAN_TO');
      this.dateSelectionErrorText = dateError;
      this.dateSelectionError = true;
      return false;
    } else {
      this.dateSelectionError = false;
    }

    this.form.controls.selectHoursFilter.setValue('');
    this.noResults = false;
    this.graphHelper = new GraphHelper();
    this.graphHelper.filteringK = k;
    this.graphHelper.filteringSensor = sensor;
    const hours = Math.abs(fromDateTime - toDateTime) / 36e5;
    this.graphHelper.HOURS = hours;
    this.graphHelper.checkGraphHours();
    this.graphHelper.langValue = this.translateService.currentLang;
    this.showDashboard(fromDateTime, toDateTime);
  }


  changeTimeSelection(event, k, sensor) {
    // tslint:disable-next-line:triple-equals
    if (event.val != '') {
      this.form.controls.fromDate.setValue('');
      this.form.controls.toDate.setValue('');
      this.graphHelper = new GraphHelper();
      this.graphHelper.filteringK = k;
      this.graphHelper.filteringSensor = sensor;

      this.graphHelper.HOURS = event.value;
      this.graphHelper.checkGraphHours();


      this.graphHelper.langValue = this.translateService.currentLang;
      const time = this.common.unixUTCSnappedSince(this.graphHelper.HOURS);
      const toDateObj = new Date();
      const toDateTime = toDateObj.getTime();
      this.showDashboard(time, toDateTime);
    }
  }

  downloadCanvas($event: any) {
    var href = document.createElement('a');
    // get the canvas
    var canvas = document.querySelector('canvas#single_' + this.key + "_" + this.sensor) as any;
    var ctx = canvas.getContext('2d');

    // set the ctx to draw beneath your current content
    ctx.globalCompositeOperation = 'destination-over';

    // set the fill color to white
    ctx.fillStyle = 'white';

    // apply fill starting from point (0,0) to point (canvas.width,canvas.height)
    // these two points are the top left and the bottom right of the canvas
    ctx.fillRect(0, 0, canvas.width+200, canvas.height+200);
    href.href = canvas.toDataURL('image/jpg');
    let text = this.title as any;
    href.download = text.replaceAll(' ','').replaceAll('|', '_') + ".jpg";
    href.click();
  }
}
